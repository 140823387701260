import React from 'react';
import {Button, Card, CardBody, Container} from "reactstrap";
import {Link, Route, Switch} from 'react-router-dom';
import User from "./components/User";
import PageTitle from "../Layout/PageTitle";
import NotFound from "../Layout/NotFound";
import ReactTable from "react-table";
import {GithubProfileType} from "../../domain/orguser";


export default function Users({org, isNew = false}) {
    const users = Object.values(org.getUsers());

    return <>

        <Switch>
            <Route exact path={org.linkToUsers()} render={() => {
                return <>
                    <PageTitle
                        heading="Users"
                        icon="pe-7s-car icon-gradient bg-mean-fruit"
                    >
                        <Link to={org.linkToUsers() + "/+"}><Button size="sm" color="info">Add user</Button></Link>
                    </PageTitle>
                    <Container fluid>
                        <Card className="mb-3">
                            <CardBody>
                                <ReactTable
                                    data={users}
                                    filterable
                                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                                    columns={[
                                        {
                                            Header: "Users",
                                            columns: [
                                                {
                                                    Header: "Email",
                                                    id: "email",
                                                    accessor: x => <Link to={x.linkToEdit()}>{x.getId()}</Link>,
                                                    filterMethod: (filter, row) => {
                                                        return String(row[filter.id].props.children).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                                                    }
                                                },
                                                {
                                                    Header: "Name",
                                                    id: "name",
                                                    accessor: x => x.getName(),
                                                },
                                                {
                                                    Header: "Linked github profiles",
                                                    id: "ghprofiles",
                                                    accessor: x => x.getProfiles(GithubProfileType).join(",")
                                                },
                                                {
                                                    Header: "Role",
                                                    id: "IsAdmin",
                                                    accessor: x => {
                                                        return x.isAdmin()? "Admin" : "Viewer"
                                                    }
                                                }
                                            ]
                                        },
                                    ]}
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </Container>
                </>
            }}/>

            <Route exact path={org.linkToUsers() + "/+"} render={() => {
                const user = org.newOrgUser({
                    Org: org.getId(),
                    Email: "",
                    IsAdmin: false
                });
                return <>
                    <PageTitle
                        heading={"Invite User"}
                        linkBack={user.org.linkToUsers()}
                        icon="pe-7s-car icon-gradient bg-mean-fruit"
                    />

                    <User user={user} isNew={true}/>
                </>
            }}/>

            <Route exact path={org.linkToUsers() + "/:email"} render={(route) => {
                const email = route.match.params.email;

                const user = users.find(x => x.getId() === email);

                return <>
                    {
                        user && <>
                            <PageTitle
                                heading={"User: " + email}
                                linkBack={user.org.linkToUsers()}
                                icon="pe-7s-car icon-gradient bg-mean-fruit"
                            />

                            <User user={user}/>
                        </>
                    }
                    {
                        !user &&
                        <NotFound text={"User not found"}/>
                    }
                </>
            }}/>
        </Switch>
    </>
}