import _ from "lodash";

export default class TraceEntry {

    org = {};
    isLoaded = false;

    constructor(trace, data) {
        this.trace = trace;
        this.setData(data);
    }

    setData(data) {
        const defaultData = {
            Org: "",
            Lib: "",

            Plugin: "",
            Repo: "",

            Folder: "",
            Details: "",

            Result: "",

            ScheduledAt: "",
            FinishedAt: ""
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        this.data = newData;
    }
}