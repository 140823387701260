import React, {useState} from 'react';
import {useInput} from "../../../hooks/useInput";
import cx from "classnames";
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";
import SweetAlert from "sweetalert-react";
import {AvForm} from "availity-reactstrap-validation";
import {Button, Card, CardBody, CardFooter, Container, FormGroup, Input, Label, Row, Col} from "reactstrap";
import {RepoType_Github} from "../../../domain/source";
import {ConnectionCheck} from "../../Source";
import {HelpTooltip} from "../../Layout/Tooltip";

export default function TechRadar({lib, technoURL}) {
    const [techRadarEnabled, setTechRadarEnabled] = useState(lib.hasTechRadarConfig());
    const {value: owner, bind: bindOwner} = useInput(lib.getTechRadarConfig().data.GithubRepoParams.Owner);
    const {value: repo, bind: bindRepo} = useInput(lib.getTechRadarConfig().data.GithubRepoParams.Repo);
    const {value: token, bind: bindToken} = useInput(lib.getTechRadarConfig().data.GithubRepoParams.Token);
    const [isPublic, setIsPublic] = useState(lib.getTechRadarConfig().data.PublicRadarSettings.IsPublic);
    const [showAdoption, setShowAdoption] = useState(lib.getTechRadarConfig().data.PublicRadarSettings.ShowAdoption);
    const [showDetails, setShowDetails] = useState(lib.getTechRadarConfig().data.PublicRadarSettings.ShowDetails);

    const [persistenceResult, setPersistenceResult] = useState({
        isLoading: false,
        error: false
    });

    const {isLoading, error} = persistenceResult;

    const handleSubmit = async (evt) => {
        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        try {
            if (techRadarEnabled) {
                const techRadar = lib.getTechRadarConfig().data;
                techRadar.GithubRepoParams.Owner = owner;
                techRadar.GithubRepoParams.Repo = repo;
                techRadar.GithubRepoParams.Token = token;
                techRadar.PublicRadarSettings.IsPublic = isPublic;
                techRadar.PublicRadarSettings.ShowAdoption = showAdoption;
                techRadar.PublicRadarSettings.ShowDetails = showDetails;

                await lib.storeTechRadar(techRadar);
            } else {
                await lib.deleteTechRadar();
            }

            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
            });
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };

    return <>
        <BlockUi tag="div" blocking={isLoading}
                 message={"Saving"}
                 loader={<Loader active type="ball-pulse"/>}>
            <SweetAlert
                title="Error"
                confirmButtonColor=""
                show={error !== false}
                text={error ? error.message + "\n\n" + error.response.data : ""}
                confirmButtonText="Okay"
                onConfirm={() => {
                    setPersistenceResult({
                        ...persistenceResult,
                        error: false
                    });
                }}
                type="error"/>
            <Container fluid>
                <AvForm onValidSubmit={handleSubmit}>
                    <Card className="mb-3">
                        <CardBody>
                            <FormGroup inline>
                                <div className="form-check-inline">
                                    <Label for="name">Tech radar enabled</Label>

                                    <div className="switch has-switch mb-2 ml-2" data-on-label="ON"
                                         data-off-label="OFF"
                                         onClick={
                                             (event) => {
                                                 setTechRadarEnabled(!techRadarEnabled);
                                             }
                                         }>
                                        <div className={cx("switch-animate", {
                                            'switch-on': techRadarEnabled,
                                            'switch-off': !techRadarEnabled
                                        })}>
                                            <input type="checkbox"/><span
                                            className="switch-left">ON</span><label>&nbsp;</label><span
                                            className="switch-right">OFF</span>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                            {
                                techRadarEnabled && <>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>Repository type</Label>
                                                <Input type="text" value={RepoType_Github} name="repoType"
                                                       disabled={true}/>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>Github repository owner/organisation</Label>
                                                <Input type="text" {...bindOwner}/>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>Github repository</Label>
                                                <Input type="text" {...bindRepo}/>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>Github access token (<a
                                                    href="https://docs.github.com/en/github/authenticating-to-github/creating-a-personal-access-token">How
                                                    to create access token?</a>)</Label>
                                                <Input type="password" {...bindToken}/>
                                            </FormGroup>

                                            <ConnectionCheck token={token} owner={owner} repository={repo}
                                                             isOrg={false}/>
                                        </Col>
                                        <Col>
                                            <FormGroup inline>
                                                <div className="form-check-inline">
                                                    <Label for="name">Publish tech radar <HelpTooltip
                                                        tip={"Tech radar will be available without authentication on a dedicated page"}/></Label>

                                                    <div className="switch has-switch mb-2 ml-2" data-on-label="ON"
                                                         data-off-label="OFF"
                                                         onClick={
                                                             (event) => {
                                                                 setIsPublic(!isPublic);
                                                             }
                                                         }>
                                                        <div className={cx("switch-animate", {
                                                            'switch-on': isPublic,
                                                            'switch-off': !isPublic
                                                        })}>
                                                            <input type="checkbox"/><span
                                                            className="switch-left">ON</span><label>&nbsp;</label><span
                                                            className="switch-right">OFF</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-muted">
                                                    Tech radar will be available soon after publishing at: <br/>
                                                    <a href={technoURL + "r/" + lib.org.getId() + "/" + lib.getId()}>{technoURL}r/{lib.org.getId()}/{lib.getId()}</a>
                                                </p>
                                            </FormGroup>
                                            {
                                                isPublic &&
                                                <div className={"ml-5"}>
                                                    <FormGroup inline>
                                                        <div className="form-check-inline">
                                                            <Label for="name">Show details</Label>

                                                            <div className="switch has-switch mb-2 ml-2"
                                                                 data-on-label="ON"
                                                                 data-off-label="OFF"
                                                                 onClick={
                                                                     (event) => {
                                                                         setShowDetails(!showDetails);
                                                                     }
                                                                 }>
                                                                <div className={cx("switch-animate", {
                                                                    'switch-on': showDetails,
                                                                    'switch-off': !showDetails
                                                                })}>
                                                                    <input type="checkbox"/><span
                                                                    className="switch-left">ON</span><label>&nbsp;</label><span
                                                                    className="switch-right">OFF</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="text-muted">
                                                            By default only Tech radar quadrants, technology name, tags and adoption state are published. <br/>
                                                            This checkmark enables publishing of technology description and history.
                                                        </p>
                                                    </FormGroup>
                                                    <FormGroup inline>
                                                        <div className="form-check-inline">
                                                            <Label for="name">Show adoption</Label>

                                                            <div className="switch has-switch mb-2 ml-2"
                                                                 data-on-label="ON"
                                                                 data-off-label="OFF"
                                                                 onClick={
                                                                     (event) => {
                                                                         setShowAdoption(!showAdoption);
                                                                     }
                                                                 }>
                                                                <div className={cx("switch-animate", {
                                                                    'switch-on': showAdoption,
                                                                    'switch-off': !showAdoption
                                                                })}>
                                                                    <input type="checkbox"/><span
                                                                    className="switch-left">ON</span><label>&nbsp;</label><span
                                                                    className="switch-right">OFF</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="text-muted">
                                                            This checkmark enables publishing of highlevel stats of the technology adoption in the library. <br/>
                                                            Only number of services using technology is published.
                                                        </p>
                                                    </FormGroup>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </>
                            }
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" className="mt-1" type="submit">Save</Button>
                        </CardFooter>
                    </Card>
                </AvForm>
            </Container>
        </BlockUi>
    </>
}