import {Button} from "reactstrap";
import React, {useState} from "react";
import {Loader} from "react-loaders";
import SweetAlert from "sweetalert-react";
import BlockUi from "react-block-ui";
import {Redirect} from "react-router-dom";

export default function DeleteLibraryButton({lib}) {
    const [persistenceResult, setPersistenceResult] = useState({
        isLoading: false,
        isDeleted: false,
        error: false
    });

    const {isLoading, isDeleted, error} = persistenceResult;

    const handleDelete = async (evt) => {
        if (window.confirm("Are you sure want to delete library?")) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: true
            });
            try {
                await lib.delete();
                setPersistenceResult({
                    ...persistenceResult,
                    isLoading: false,
                    isDeleted: true
                });
            } catch (e) {
                setPersistenceResult({
                    ...persistenceResult,
                    isLoading: false,
                    error: e
                });
            }
        }

    };

    if (isDeleted) {
        return <Redirect to={lib.org.linkToDashboard()}/>
    }

    return <BlockUi tag="div" blocking={isLoading}
                    message={"Saving"}
                    className="d-inline-block"
                    loader={<Loader active type="ball-pulse"/>}>
        <SweetAlert
            title="Error"
            confirmButtonColor=""
            show={error !== false}
            text={error ? error.message + "\n\n" + error.response.data : ""}
            confirmButtonText="Okay"
            onConfirm={() => {
                setPersistenceResult({
                    ...persistenceResult,
                    error: false
                });
            }}
            type="error"/>
        <Button color="danger" size={"sm"} className="ml-1" onClick={handleDelete}>Delete library</Button>
    </BlockUi>
}