import _ from "lodash";
import {RepoType_Github} from "./source";

export default class TechRadarConfig {
    data = {};

    constructor(lib, data) {
        this.lib = lib;
        this.setData(data);
    }

    setData(data) {
        const defaultData = {
            RepoType: RepoType_Github,

            GithubRepoParams: {
                Owner: "",
                Repo: "",
                Token: ""
            },

            PublicRadarSettings: {
                IsPublic: false,
                ShowDetails: false,
                ShowAdoption: false
            }
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        this.data = newData;
    }

    async save() {
        return await this.lib.storeTechRadar(this);
    }

    async delete() {
        return await this.lib.deleteTechRadar();
    }
}