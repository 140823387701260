import {useInput} from "../../../hooks/useInput";
import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";
import SweetAlert from "sweetalert-react";
import PageTitle from "../../Layout/PageTitle";
import {Button, Card, CardBody, CardFooter, Container, FormGroup, Label} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";

export default function NewLibrary({lib}) {
    const {value: name, bind: bindName} = useInput(lib.getName());

    const [persistenceResult, setPersistenceResult] = useState({
        isLoading: false,
        redirectAfterSave: false,
        error: false
    });

    const {isLoading, redirectAfterSave, error} = persistenceResult;

    const handleSubmit = async (evt) => {
        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        lib.data.Name = name;
        try {
            await lib.save();
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                redirectAfterSave: true
            });
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };

    window.library = lib;

    if (redirectAfterSave) {
        return <Redirect to={lib.linkToOverview()}/>
    }

    return <>
        <BlockUi tag="div" blocking={isLoading}
                 message={"Saving"}
                 loader={<Loader active type="ball-pulse"/>}>
            <SweetAlert
                title="Error"
                confirmButtonColor=""
                show={error !== false}
                text={error ? error.message + "\n\n" + error.response.data : ""}
                confirmButtonText="Okay"
                onConfirm={() => {
                    setPersistenceResult({
                        ...persistenceResult,
                        error: false
                    });
                }}
                type="error"/>

            <PageTitle
                heading={"Add new library"}
                icon="pe-7s-car icon-gradient bg-mean-fruit"
            />
            <Container fluid>
                <AvForm onValidSubmit={handleSubmit}>
                    <Card className="mb-3">
                        <CardBody>
                            <FormGroup>
                                <Label for="name">Library name</Label>
                                <AvField type="text" name="name" id="name" {...bindName}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter a library name'
                                             },
                                             pattern: {
                                                 value: "^[^/]+$",
                                                 errorMessage: 'Library name should not contain /'
                                             }
                                         }}
                                         placeholder="name"/>
                            </FormGroup>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" className="mt-1" type="submit">Save</Button>
                        </CardFooter>
                    </Card>
                </AvForm>
            </Container>

        </BlockUi>
    </>
}