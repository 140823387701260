import React from 'react';
import PageTitle from "../Layout/PageTitle";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import {Link} from "react-router-dom";

export default function OrgSelector({user, doxyURL}) {
    return <>
        <PageTitle
            heading="Choose organisation"
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        >
            {
                user.canCreateOrg() &&
                <div className="btn-actions-pane-right">
                    <Link to={"/+"}><Button size="sm" color="info">Create new organisation</Button></Link>
                </div>
            }
        </PageTitle>
        <Container fluid>
            {
                !user.canCreateOrg() &&
                <Row>
                    <Col className={"mb-3"}>
                        <Alert color="info">
                            <h4 className="alert-heading">Service360.io is in invitation mode.</h4>
                            We are sorry, you can not create new Organisation. <br/>
                            Please contact <a href={"mailto:support@service360.io"}>support@service360.io</a> to get
                            your invite.
                        </Alert>
                    </Col>
                </Row>
            }
            {
                Object.values(user.getAdminOrgs()).length !== 0 &&
                <Row>
                    {
                        Object.values(user.getAdminOrgs()).map((org, i) => {
                            return <>
                                <Col md={3}>
                                    <OrgCard org={org} />
                                </Col>
                            </>
                        })
                    }
                </Row>
            }
            {
                Object.values(user.getAdminOrgs()).length !== Object.values(user.getOrgs()).length &&
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                View-only organisations
                            </CardHeader>
                            <CardBody>
                                <ListGroup flush>
                                    {
                                        Object.values(user.getOrgs()).map((org, i) => {
                                            if (user.getAdminOrgs()[org.getId()] !== undefined) {
                                                return null;
                                            }
                                            return (
                                                <ListGroupItem key={org.getId()}>
                                                    <a href={doxyURL + org.getId()}>{org.getName()}</a>
                                                </ListGroupItem>
                                            )
                                        })
                                    }
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </Container>
    </>;
}

function OrgCard({org}) {
    return <Card className="main-card mb-3">
        <ListGroup flush>
            <ListGroupItem className="center-elem">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3">
                            <div className="icon-wrapper border-light rounded m-0">
                                <div className="icon-wrapper-bg bg-light"/>
                                <i className="lnr-cog icon-gradient bg-happy-itmeo"/>
                            </div>
                        </div>
                        <div className="widget-content-left ">
                            <div className="widget-heading">
                                <h5 className="menu-header-title text-capitalize">
                                    {
                                        org.isMarkedForDeletion() &&
                                        <span className="text-muted">
                                                            {org.getName()} (scheduled for deletion and going to be removed soon)
                                                        </span>
                                    }
                                    {
                                        !org.isMarkedForDeletion() &&
                                        <Link
                                            to={"/" + org.getId()}>{org.getName()}</Link>
                                    }</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <Row>
                    <Col sm="12" md="12" className="align-self-center">
                        <Row>
                            <Col sm="12" md="12" lg={6}>
                                <div className="widget-chart">
                                    <div className="widget-chart-content">
                                        <div
                                            className="widget-numbers text-warning fsize-3">
                                            {org.data.LibrariesCount}
                                        </div>
                                        <div className="widget-subheading pt-1">
                                            Libraries
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12" md="12" lg={6}>
                                <div className="widget-chart">
                                    <div className="widget-chart-content">
                                        <div
                                            className="widget-numbers text-success fsize-3">
                                            {org.data.UsersCount}
                                        </div>
                                        <div className="widget-subheading pt-1">
                                            Users
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ListGroupItem>
        </ListGroup>
    </Card>
}