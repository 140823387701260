import _ from "lodash";

export default class ScrapeLog {

    org = {};
    isLoaded = false;

    constructor(trace, data) {
        this.trace = trace;
        this.setData(data);
    }

    setData(data) {
        const defaultData = {
            Org: "",
            Lib: "",

            Level: "",
            Message: "",

            Name: "",
            Type: "",
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        this.data = newData;
    }
}