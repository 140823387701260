import Source from "./source";
import TechRadarConfig from "./techradarconfig";
import PluginConfig from "./pluginconfig";

export default class Library {

    loader = undefined;
    data = {};
    org = {};
    overview = {};
    isOverviewLoaded = false;

    constructor(loader, org, data) {
        this.loader = loader;
        this.org = org;
        this.data = data;
        if (!this.data.Sources) {
            this.data.Sources = [];
        }
        if (!this.data.Plugins) {
            this.data.Plugins = {};
        }
    }

    async loadOverview() {
        const data = await this.org.user.doxyLoader.get("/v1/orgs/" + this.org.getId() + "/libraries/" + this.getId());
        this.overview = data.data;
        this.isOverviewLoaded = true;
    }

    getOverview() {
        return this.overview;
    }

    getId() {
        return this.getName();
    }

    getName() {
        return this.data.Name;
    }

    getScrapeHour() {
        return this.data.GSIScheduleMarkerScrapeHour;
    }

    newSource(data) {
        return new Source(this, data);
    }

    hasTechRadarConfig() {
        return !!this.data.TechRadar;
    }

    getTechRadarConfig() {
        return new TechRadarConfig(this, this.data.TechRadar);
    }

    async storeSource(source) {
        this.data.Sources = this.data.Sources.filter((s) => {
            return s.Name !== source.getName();
        });
        this.data.Sources.push(source.getData());

        return await this.save();
    }

    async storeTechRadar(config) {
        this.data.TechRadar = config;

        return await this.save();
    }

    async deleteTechRadar() {
        delete this.data.TechRadar;

        return await this.save();
    }

    getPluginConfigs() {
        return this.data.Plugins;
    }

    hasPluginConfig(type) {
        return this.data.Plugins[type] !== undefined;
    }

    // This is only temp in-memory enablement.
    // storePluginConfig must be used to persist configuration
    enablePlugin(type) {
        this.data.Plugins[type] = {Type: type, PrivateConfig:{}, PublicConfig: {}}
    }

    getPluginConfig(type) {
        let cfg = this.data.Plugins[type];
        if (cfg === undefined) {
            cfg = {Type: type, PrivateConfig:{}, PublicConfig: {}}
        }

        return new PluginConfig(this, cfg);
    }

    async storePluginConfig(config) {
        this.data.Plugins[config.getData().Type] = config.getData();

        return await this.save();
    }

    async deletePluginConfig(config) {
        delete this.data.Plugins[config.getData().Type];

        return await this.save();
    }

    async forceScrape() {
        const res = await this.loader.post("/orgs/" + this.org.getId() + "/libs/" + this.getId() + "/scrape");
        return res.data;
    }

    async removeSource(source) {
        this.data.Sources = this.data.Sources.filter((s) => {
            return s.Name !== source.getName();
        });

        return await this.save();
    }

    getSources() {
        return this.data.Sources.map((sourceData) => {
            return this.newSource(sourceData);
        }).sort((a, b) => {
            return a.getName() > b.getName();
        })
    }

    async save() {
        return await this.org.saveLibrary(this)
    }

    async delete() {
        return await this.org.deleteLibrary(this)
    }

    getLastTrace() {
        const traces = Object.values(this.org.getTraces())
            .filter(x => x.getData().TargetType === "library" && x.getData().Target === this.getId());

        if (traces.length === 0) {
            return undefined
        }

        return traces[0]
    }

    linkToOverview() {
        return this.org.linkToLibraries() + "/" + this.getId()
    }

    linkToSources() {
        return this.linkToOverview() + "/sources"
    }

    linkToPlugins() {
        return this.linkToOverview() + "/plugins"
    }

    linkToTechRadarConfig() {
        return this.linkToOverview() + "/techradarconfig"
    }

    linkToPluginConfig(pluginType) {
        const path = this.linkToPlugins();

        return pluginType === undefined ? path : path + "/" + pluginType;
    }
}