import React from 'react';
import {Badge, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import usePlugins from "../../../hooks/usePlugins";
import cx from "classnames";
import {Link, useHistory} from 'react-router-dom';
import {IconTooltip, Tooltip} from "../../Layout/Tooltip";
import {faCog} from "@fortawesome/free-solid-svg-icons";

export default function PluginsOverview({library}) {
    const plugins = usePlugins();
    const enabledPlugins = Object.keys(plugins.getTypes())
        .filter(type => library.hasPluginConfig(type));

    const notEnabledPlugins = Object.keys(plugins.getTypes())
        .filter(type => !library.hasPluginConfig(type));

    return <>
        {
            enabledPlugins.length !== 0 &&
            <>
                <Card className="mb-3">
                    <CardHeader>
                        Enabled plugins
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {
                                enabledPlugins.map(type => <Col lg={4}><PluginCard library={library}
                                                                                   type={type}/></Col>)
                            }
                        </Row>
                    </CardBody>
                </Card>
            </>
        }
        {
            notEnabledPlugins.length !== 0 &&
            <>
                <Card className="mb-3">
                    <CardHeader>
                        Available plugins
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {
                                notEnabledPlugins.map(type => <Col lg={4}><PluginCard library={library}
                                                                                      type={type}/></Col>)
                            }
                        </Row>

                    </CardBody>
                </Card>
            </>
        }
    </>
}

function PluginCard({library, type}) {
    const plugins = usePlugins();
    const plugin = plugins.getTypes()[type];
    const history = useHistory()

    return <Card className={"card-hover-shadow card-border"}>
        <CardHeader>
            {/*{*/}
            {/*    plugin.image &&*/}
            {/*        <img src={plugin.image} alt={""} width={"45"}/>*/}
            {/*}*/}
            {plugin.name}
            {
                plugin.beta &&
                <Tooltip className={"ml-1"}
                         tip={plugin.beta}><Badge color={"warning"}>beta</Badge></Tooltip>
            }
            <div className="btn-actions-pane-right actions-icon-btn">
                {
                    !library.hasPluginConfig(type) &&
                    <div className="switch has-switch" data-on-label="ON"
                         data-off-label="OFF"
                         onClick={
                             (event) => {
                                 library.enablePlugin(type);
                                 history.push(library.linkToPluginConfig(type));
                                 return false;
                             }
                         }>
                        <div className={cx("switch-animate", {
                            'switch-on': false,
                            'switch-off': true
                        })}>
                            <input type="checkbox"/><span
                            className="switch-left">ON</span><label>&nbsp;</label><span
                            className="switch-right">OFF</span>
                        </div>
                    </div>
                }
                {
                    library.hasPluginConfig(type) &&
                    <Link to={library.linkToPluginConfig(type)} className={"mr-1"}><IconTooltip tip={"Configure plugin"} icon={faCog}/></Link>
                }
            </div>
        </CardHeader>
        <CardBody>{plugin.description}</CardBody>
    </Card>
}