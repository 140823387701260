import _ from 'lodash'

export const RepoType_Github = "github";
export const SourceType_Org = "org";
export const SourceType_Single = "single";
export const SourceType_Mono = "mono";

export default class Source {

    lib = undefined;
    data = {};

    constructor(lib, data) {
        this.lib = lib;
        this.setData(data);
    }

    setData(data) {
        const defaultData = {
            RepoType: RepoType_Github,

            SourceType: SourceType_Org,

            GithubRepoParams: {
                Owner: "",
                Repo: "",
                Token: "",
                SkipForks: true
            },

            Blacklist: [],

            Plugins: {
                Passport: {
                    Folder: "",
                    Filename: "README.md"
                },

                PlantUML: {
                    Enabled: true,
                    Folder: "",
                    Filename: "deps.s360.puml"
                },

                API: {
                    Enabled: true,
                    Folder: "docs/api",
                    Filename: "openapi.yaml"
                },

                Docs: {
                    Enabled: true,
                    Folder: "docs"
                },

                Releases: {
                    Enabled: true,
                    ReleasesParseMethod: "githubReleases"
                },

                Contributors: {
                    Enabled: true
                }
            }
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        if (data.Blacklist !== undefined) {
            newData.Blacklist = data.Blacklist;
        }
        this.data = newData;
    }

    getId() {
        return this.getName();
    }

    getName() {
        return this.data.Name;
    }

    getData() {
        return this.data;
    }

    async save() {
        return await this.lib.storeSource(this);
    }

    async delete() {
        return await this.lib.removeSource(this);
    }

    linkToEdit() {
        return this.lib.linkToSources() + "/" + this.getName();
    }

    linkToClone() {
        return this.lib.linkToSources() + "/" + this.getName() + "/clone";
    }

    getSourceType() {
        return this.getData().SourceType;
    }

    getGithubRepoParams() {
        return this.getData().GithubRepoParams;
    }

    getPlantUMLPlugin() {
        return this.getData().Plugins.PlantUML;
    }

    getPassportPlugin() {
        return this.getData().Plugins.Passport;
    }

    getAPIPlugin() {
        return this.getData().Plugins.API;
    }

    getDocsPlugin() {
        return this.getData().Plugins.Docs;
    }

    getReleasesPlugin() {
        return this.getData().Plugins.Releases;
    }

    getContributorsPlugin() {
        return this.getData().Plugins.Contributors;
    }

    getBlacklist() {
        return this.getData().Blacklist !== null ? this.getData().Blacklist : [];
    }

}