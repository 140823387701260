import React from 'react';
import PageTitle from "../Layout/PageTitle";
import {Card, CardBody, Container} from "reactstrap";
import {Link} from "react-router-dom";
import ReactTable from "react-table";

export default function Traces({org}) {
    const query = new URLSearchParams(document.location.search);

    return <>
        <PageTitle
            heading="Traces"
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        />
        <Container fluid>
            <Card className="mb-3">
                <CardBody>
                    <ReactTable
                        data={Object.values(org.getTraces())}
                        filterable
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                        defaultFiltered={[
                            {
                                "id": "Target",
                                "value": query.get("target") || ""
                            }
                        ]}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "ID",
                                        id: "id",
                                        accessor: x => x.getId(),
                                        Cell: row => (
                                            <Link to={org.linkToTraces() + "/" + row.value}>{row.value}</Link>
                                        )
                                    },
                                    {
                                        Header: "Target",
                                        id: "Target",
                                        accessor: x => [x.getData().TargetType, x.getData().Target].filter(x => x !== "").join(": ")
                                    },
                                    {
                                        Header: "Initiator",
                                        id: "Initiator",
                                        accessor: x => x.getData().Initiator
                                    },
                                    {
                                        Header: "CreatedAt",
                                        id: "createdAt",
                                        accessor: x => x.getCreatedAt().toLocaleString()
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={20}
                        className="-striped -highlight"
                    />
                </CardBody>
            </Card>
        </Container>
    </>;
}
