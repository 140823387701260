import React, {Fragment} from "react";
import {Link} from "react-router-dom";

export default class HeaderLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false
        };

    }

    toggleEnableClosedSidebar = () => {
        let {enableClosedSidebar, setEnableClosedSidebar} = this.props;
        setEnableClosedSidebar(!enableClosedSidebar);
    };

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        // let {
        //     title,
        //     enableClosedSidebar,
        // } = this.props;
        //
        // const {} = this.state;

        return (
            <Fragment>
                <div className="app-header__logo">
                    {/*<div className="logo-src">{title}</div>*/}
                    <Link to={"/"}>
                        <div className="logo-src"></div>
                    </Link>
                    {/*<div className="header__pane ml-auto">*/}
                    {/*    <div onClick={this.toggleEnableClosedSidebar}>*/}
                    {/*        <Hamburger*/}
                    {/*            active={enableClosedSidebar}*/}
                    {/*            type="elastic"*/}
                    {/*            onClick={() => this.setState({active: !this.state.active})}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </Fragment>
        )
    }
}