import React, {useState} from "react";
import {useInput} from "../../hooks/useInput";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label, ListGroup, ListGroupItem,
    Row
} from "reactstrap";
import {HelpTooltip} from "../../components/Layout/Tooltip";
import ExternalLink from "../../components/Layout/ExternalLink";

const pluginDescriptor = {
    type: "PerspectiveIframeTab",
    name: "Custom Perspective",
    description: "Show custom page in an iframe accessible via Tab in the main top navigation.",
    render: (config, renderers) => <PerspectiveIframeConfig config={config} renderers={renderers}/>,
}

export default pluginDescriptor;

function PerspectiveIframeConfig({config, renderers}) {
    const [singleTabs, setSingleTabs] = useState(config.getData().PublicConfig.Tabs || []);
    const [wasModified, setWasModified] = useState(false);

    const persist = async () => {
        if (singleTabs.length !== 0) {
            config.setData({
                PublicConfig: {
                    Tabs: singleTabs
                }
            })
            await config.save()
        } else {
            await config.delete()
        }
    }

    return <renderers.BasicFrame pluginDescriptor={pluginDescriptor} persist={persist} wasModified={wasModified}
                                 onSuccess={() => {
                                     setWasModified(false);
                                 }}>
        <Card>
            <ListGroup flush>
                {
                    singleTabs.map((cfg, pos) => {
                        const order = pos;
                        const save = data => {
                            singleTabs[order] = data;
                            setSingleTabs([...singleTabs]);
                            setWasModified(true);
                        }
                        const remove = () => {
                            singleTabs.splice(order, 1);
                            setSingleTabs([...singleTabs]);
                            setWasModified(true);
                        }
                        return <ListGroupItem key={cfg.name + "_" + order}>
                            <SinglePerspectiveIframe singleTabConfig={cfg}
                                                     remove={remove} save={save} editByDefault={cfg.name === ""}/>
                        </ListGroupItem>
                    })
                }
                <ListGroupItem>
                    <Button onClick={() => {
                        singleTabs.push({name: "", icon: "", route: "", source: ""});
                        setSingleTabs([...singleTabs])
                    }} color={"primary"}>Add tab</Button>
                </ListGroupItem>
            </ListGroup>
        </Card>
    </renderers.BasicFrame>
}

function SinglePerspectiveIframe({singleTabConfig, save, remove, editByDefault = false}) {
    const [isModified, setIsModified] = useState(false);
    const [checkFrame, setCheckFrame] = useState(false);
    const [edit, setEdit] = useState(editByDefault);
    const {value: name, bind: bindName} = useInput(singleTabConfig.name || "", () => setIsModified(true));
    const {value: icon, bind: bindIcon} = useInput(singleTabConfig.icon || "", () => setIsModified(true));
    const {value: route, bind: bindRoute} = useInput(singleTabConfig.route || "", () => setIsModified(true));
    const {value: source, bind: bindSource} = useInput(singleTabConfig.source || "", () => setIsModified(true));

    return <Container fluid className={"p-0"}>
        {
            !edit &&
            <Row>
                <Col lg={10}>
                    <Container fluid className={"p-0"}>
                        <Row>
                            <Col><b>Tab name:</b> {name}</Col>
                            <Col><b>Icon:</b> <img src={icon} width={30} className={"mr-3"} alt={""}/>
                                <ExternalLink to={icon}>{icon}</ExternalLink>
                            </Col>
                            <Col><b>Route key:</b> {route}</Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>Source:</b> <ExternalLink to={source}>{source}</ExternalLink>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col>
                    <Button onClick={() => setEdit(true)} color={"primary"} className="mr-1">Edit</Button>
                    <Button onClick={() => {
                        if (window.confirm("Are you sure?")) {
                            remove()
                        }
                    }} color={"danger"}>Remove</Button>
                </Col>
            </Row>
        }
        {
            edit && <>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Tab name</Label>
                            <Input type="text" {...bindName}/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Icon URL <HelpTooltip tip={"URL of an icon to be used in top nav bar"}/></Label>
                            <InputGroup>
                                {
                                    icon && <InputGroupAddon addonType="prepend">
                                        <img src={icon} width={30} className={"mr-3"} alt={""}/>
                                    </InputGroupAddon>
                                }
                                <Input type="text" {...bindIcon}/>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Route key <HelpTooltip
                                tip={"This key will be used in the URL string of the browser in the client interface of the S360 app"}/></Label>
                            <Input type="text" {...bindRoute}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Iframe URL <HelpTooltip
                                tip={"Not all websites allow to display themselves in an iframe. Check X-Frame-Options, Content-Security-Policy headers for further details"}/></Label>
                            <InputGroup>
                                <Input type="text" {...bindSource}/>
                                <InputGroupAddon addonType="append">
                                    <Button onClick={() => setCheckFrame(source)} color={"secondary"}>Check</Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        {
                            checkFrame && <Card body outline color="secondary">
                                <CardTitle tag="h5">{source}</CardTitle>
                                <CardBody>
                                    <iframe src={source} width="100%" height="100%" title={source}/>
                                </CardBody>
                                <CardFooter>
                                    If you see expected content above then your frame is good to go!
                                </CardFooter>
                            </Card>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup className={"mt-3"}>
                            <Button onClick={() => {
                                save({
                                    name: name,
                                    icon: icon,
                                    route: route,
                                    source: source
                                });
                                setIsModified(false);
                                setEdit(false);
                            }} color={"primary"} disabled={!isModified} className="mr-1">Update</Button>

                            <Button onClick={() => {
                                if (window.confirm("Are you sure?")) {
                                    remove()
                                }
                            }} color={"danger"}>Remove</Button>
                        </FormGroup>
                    </Col>
                </Row>
            </>
        }
    </Container>
}