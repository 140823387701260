import React from "react";
import {UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

let counter = 0;

export function Tooltip({children, tip, className}) {
    const id = "UncontrolledTooltip_" + counter++;
    return <>
        <span id={id} className={className}>{children}</span>
        <UncontrolledTooltip target={id}>
            {tip}
        </UncontrolledTooltip>
    </>
}

export function IconTooltip({tip, icon=faQuestionCircle, className}) {
    return <Tooltip tip={tip} className={className}><FontAwesomeIcon icon={icon}/></Tooltip>
}

export function HelpTooltip({tip}) {
    return <IconTooltip tip={tip} />
}