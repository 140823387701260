import React, {useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalHeader,
    Row
} from "reactstrap";
import {Redirect} from "react-router-dom";
import {Typeahead} from 'react-bootstrap-typeahead';
import {useInput} from "../../../hooks/useInput";
import {Loader} from "react-loaders";
import SweetAlert from "sweetalert-react";
import BlockUi from "react-block-ui";
import {AvForm} from "availity-reactstrap-validation";
import PageTitle from "../../Layout/PageTitle";
import {isValidEmail} from "../../../utils/validation";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {IconTooltip} from "../../Layout/Tooltip";
import User from "../../Users/components/User";
import {Contacts} from "../../Users/components/Contacts";

export default function Team({team, isNew = false}) {
    const {value: name, bind: bindName} = useInput(team.getId());
    const [lead, setLead] = useState(team.getData().Lead);
    const {value: mission, bind: bindMission} = useInput(team.getData().Mission);
    const [members, setMembers] = useState(team.getData().Members || []);
    const [contacts, setContacts] = useState(team.getData().Contacts || []);

    window.s360team = team;
    window.members = members;

    const [persistenceResult, setPersistenceResult] = useState({
        isLoading: false,
        redirectAfterSave: false,
        isDeleted: false,
        error: false
    });

    const {isLoading, redirectAfterSave, isDeleted, error} = persistenceResult;

    const handleSubmit = async (evt) => {
        team.setData({
            Name: name,
            Lead: lead,
            Mission: mission,
            Contacts: contacts
        });
        //Broken SetData >_<
        team.data.Members = members.map(x => x.trim()).filter(x => !!x);
        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        try {
            await team.save();
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                redirectAfterSave: true
            });
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };

    const handleDelete = async (evt) => {
        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        try {
            await team.delete();
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                isDeleted: true
            });
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };


    if (isDeleted || redirectAfterSave) {
        return <Redirect to={team.org.linkToTeams()}/>;
    }

    const title = isNew ? "New team" : "Teams: " + team.getId();

    return <>
        <PageTitle
            heading={title}
            linkBack={team.org.linkToTeams()}
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        />
        <Container fluid className={"team"}>
            <BlockUi tag="div" blocking={isLoading}
                     message={"Saving"}
                     loader={<Loader active type="ball-pulse"/>}>
                <SweetAlert
                    title="Error"
                    confirmButtonColor=""
                    show={error !== false}
                    text={error ? error.message + "\n\n" + error.response.data : ""}
                    confirmButtonText="Okay"
                    onConfirm={() => {
                        setPersistenceResult({
                            ...persistenceResult,
                            error: false
                        });
                    }}
                    type="error"/>
                <AvForm onValidSubmit={handleSubmit}>
                    <Card className="mb-3">
                        <CardBody>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Team</Label>
                                        <Input type="text"  {...bindName}
                                               placeholder="Team name"/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Team mission</Label>
                                        <Input type="textarea" {...bindMission} />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Contacts</Label>
                                        <Contacts contacts={contacts} setContacts={setContacts}/>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Team lead</Label>
                                        <TeamMemberRow email={lead} team={team} save={setLead} autoFocus={false} remove={() => {
                                            setLead("");
                                        }}/>
                                    </FormGroup>
                                    <Label>Members</Label>
                                    <TeamMembers team={team} members={members} setMembers={setMembers}/>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" type="submit" disabled={name.trim() === ""}>Save</Button>
                            {
                                !isNew &&
                                <Button color="danger" className="ml-1" onClick={handleDelete}>Delete</Button>
                            }
                        </CardFooter>
                    </Card>
                </AvForm>
            </BlockUi>
        </Container>
    </>
}

function TeamMembers({team, members, setMembers}) {
    return <ul className="list-unstyled">
        {
            members.map((x, pos) => {
                const order = pos;
                const save = data => {
                    members[order] = data;
                    setMembers([...members]);
                }
                const remove = () => {
                    members.splice(order, 1);
                    setMembers([...members]);
                }
                return <li key={x + "_" + order}><TeamMemberRow team={team} email={x} save={save} remove={remove}/></li>
            })
        }
        <li>
            <Button onClick={() => {
                members.push("");
                setMembers([...members]);
            }} color={"success"}>Add team member</Button>
        </li>
    </ul>
}

function TeamMemberRow({team, email, save, remove, autoFocus=true}) {
    const {value: mail, setValue: setMail} = useInput(email);
    const [showUser, setShowUserForm] = useState(false);

    const toggleUserForm = () => {
        setShowUserForm(!showUser);
        return null;
    }

    return <FormGroup>
        {
            // new
            email === "" && <>
                <InputGroup>
                    <Typeahead
                        id="search"
                        labelKey="value"
                        autoFocus={autoFocus}
                        emptyLabel={false}
                        maxResults={15}
                        multiple={false}
                        onChange={x => setMail(x[0])}
                        onInputChange={setMail}
                        options={Object.keys(team.org.getUsers())}
                        placeholder="Email"
                    />
                    <InputGroupAddon addonType="append">
                        <Button disabled={!isValidEmail(mail)}
                                size={"sm"} className={"ml-1"} color={"primary"}
                                onClick={() => {
                                    console.log('before', mail, email)
                                    save(mail)}}>Save</Button>
                        {
                            remove &&
                            <Button className={"ml-1"} size={"sm"} color={"danger"} onClick={remove}><IconTooltip
                                tip={"Remove from the team"} icon={faWindowClose}/></Button>
                        }
                    </InputGroupAddon>
                    <FormFeedback valid={isValidEmail(mail)}>Please provide a valid email address</FormFeedback>
                </InputGroup>
            </>
        }
        {
            email !== "" && <>
                {email}
                {
                    team.org.getUsers()[email] && <>
                        <Button size={"sm"} className={"ml-1"} color={"success"}
                                onClick={toggleUserForm}>User</Button>
                    </>
                }
                {
                    !team.org.getUsers()[email] && <>
                        <Button size={"sm"} className={"ml-1"} color={"primary"}
                                onClick={toggleUserForm}>Invite</Button>
                    </>
                }
                {
                    remove && <Button className={"ml-1"} size={"sm"} color={"danger"} onClick={() => {
                        if (window.confirm("This user will be removed from the team, but not from the organisation. Do you want to proceed?")) {
                            remove()
                        }
                    }}><IconTooltip tip={"Remove from the team"} icon={faWindowClose}/></Button>
                }
                {
                    showUser &&
                    <>
                        <Modal isOpen={showUser} toggle={toggleUserForm} size={"lg"}>
                            <ModalHeader
                                toggle={toggleUserForm}>{team.org.getUsers()[email] ? "User: " + email : "Invite user"}</ModalHeader>
                            <User isNew={team.org.getUsers()[email] === undefined}
                                  user={team.org.getUsers()[email] ? team.org.getUsers()[email] : team.org.newOrgUser({EMail: email})}
                                  onSave={toggleUserForm}/>
                        </Modal>
                    </>
                }
            </>
        }
    </FormGroup>
}
