let adminLoader = undefined;
let doxyLoader = undefined;

export const useLoader = () => {
    return {
        adminLoader,
        doxyLoader,
        setDoxyLoader: x => doxyLoader = x,
        setAdminLoader: x => adminLoader = x
    };
};