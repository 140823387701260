export function formatTimestampToDateTime(ts) {
    if (!(ts instanceof Date)) {
        ts = new Date(ts * 1000)
    }
    return ts.toLocaleString()
}

export function formatTimestampToDate(ts) {
    if (!(ts instanceof Date)) {
        ts = new Date(ts * 1000)
    }
    return ts.toLocaleDateString()
}