import React, {useState} from 'react';
import {useInput} from "../../hooks/useInput";
import {Redirect} from "react-router-dom";
import {AvField, AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Button, Card, CardBody, CardFooter, CardTitle, Container, FormGroup, Label,} from "reactstrap";
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";
import SweetAlert from "sweetalert-react";
import PageTitle from "../Layout/PageTitle";

export default function Organisation({org, isNew = false}) {
    const {value: name, bind: bindName} = useInput(org.getName());
    const {value: slug, bind: bindSlug} = useInput(org.getSlug());

    const [isLoading, setIsLoading] = useState(false);
    const [redirectToSelf, setRedirectToSelf] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [error, setError] = useState(false);

    window.o = org;

    const handleSubmit = async (evt) => {
        setIsLoading(true);
        org.data.Name = name;
        org.data.Slug = slug;
        try {
            await org.save(isNew);
            setRedirectToSelf(true);
        } catch (e) {
            setError(e)
        }
        setIsLoading(false);
    };

    const handleDelete = async (evt) => {
        if (window.confirm("Are you sure want to delete organisation?")) {
            setIsLoading(true);
            try {
                await org.delete();
                setIsDeleted(true);
            } catch (e) {
                setError(e)
            }
            setIsLoading(false);
        }
    };

    if (isDeleted) {
        return <Redirect to={"/"}/>
    }
    if (redirectToSelf) {
        return <Redirect to={org.linkToSettings()}/>
    }

    const pageTitle = isNew ? "Create new organisation" : "Settings";

    return <>
        <PageTitle
            heading={pageTitle}
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        />
        <Container fluid={!isNew}>
            <BlockUi tag="div" blocking={isLoading}
                     message={"Saving"}
                     loader={<Loader active type="ball-pulse"/>}>
                <SweetAlert
                    title="Error"
                    confirmButtonColor=""
                    show={error !== false}
                    text={error ? error.message + "\n\n" + error.response.data : ""}
                    confirmButtonText="Okay"
                    onConfirm={() => {
                        setError(false);
                    }}
                    type="error"/>
                <AvForm onValidSubmit={handleSubmit}>
                    <Card className="mb-3">
                        <CardBody>
                            <CardTitle>{org.getName()}</CardTitle>

                            <FormGroup>
                                <Label for="name">Organisation name</Label>
                                <AvField type="text" name="name" id="name" {...bindName}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter an organisation name'
                                             }
                                         }}
                                         placeholder="Organisation"/>
                            </FormGroup>

                            <AvGroup>
                                <Label for="slug">Organisation slug</Label>
                                <AvField type="text" name="slug" id="slug" {...bindSlug}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter an organisation slug (will be used in URL)'
                                             },
                                             pattern: {
                                                 value: "^[A-Za-z0-9]+$",
                                                 errorMessage: 'Your organisation slug must be composed only with letters and numbers'
                                             }
                                         }}
                                         disabled={!isNew}/>
                            </AvGroup>

                        </CardBody>
                        <CardFooter>
                            <Button color="primary" type="submit">Save</Button>
                            {
                                !isNew && <Button color="danger" className="ml-1" onClick={handleDelete}>Delete</Button>
                            }
                        </CardFooter>
                    </Card>
                </AvForm>
            </BlockUi>
        </Container>
    </>
}