import React, {useMemo, useState} from 'react';
import PageTitle from "../../Layout/PageTitle";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    ListGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import ReactTable from "react-table";
import classnames from "classnames";
import {Link, useHistory} from "react-router-dom";
import {formatTimestampToDateTime} from "../../../utils/date";
import {ListGroupWidgetItem} from "../../Library/components/LibraryOverview";

export default function Trace({trace, org}) {
    const router = useHistory();

    let title = "Trace " + trace.getId();
    const query = new URLSearchParams(document.location.search);

    let selectedTab = query.get("tab");
    if (selectedTab !== "overview" && selectedTab !== "details" && selectedTab !== "dataissues") {
        selectedTab = "overview";
    }

    const [activeTab, setActiveTab] = useState(selectedTab);

    if (activeTab !== selectedTab) {
        setActiveTab(selectedTab);
    }

    window.trace = trace;

    const progress = trace.getProgress();
    const progressValue = "(" + progress.Finished + "/" + progress.Total + ") " + parseFloat(progress.Progress).toFixed(2) + "%";

    return <>
        <PageTitle
            linkBack={org.linkToTraces()}
            heading={title}
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        >
            <Link to="/reload" className="ml-1"><Button size="sm" color="info">Reload</Button></Link>
        </PageTitle>
        <Container fluid>
            <div className="card no-shadow bg-transparent no-border rm-borders mb-3">
                <Card>
                    <Row className="no-gutters">
                        <Col md="12" lg="4">
                            <ListGroup flush>
                                <ListGroupWidgetItem widgetClass={""} noSubHeading={true} name={"Target type"}
                                                     value={trace.getData().TargetType}/>
                                <ListGroupWidgetItem widgetClass={""} noSubHeading={true} name={"Target"}
                                                     value={trace.getData().Target}/>
                            </ListGroup>
                        </Col>
                        <Col md="12" lg="4">
                            <ListGroup flush>
                                <ListGroupWidgetItem widgetClass={""} noSubHeading={true} name={"Initiator"}
                                                     value={trace.getData().Initiator}/>
                                <ListGroupWidgetItem widgetClass={""} noSubHeading={true} name={"Progress"}
                                                     value={progressValue}/>
                            </ListGroup>
                        </Col>
                        <Col md="12" lg="4">
                            <ListGroup flush>
                                <ListGroupWidgetItem widgetClass={""} noSubHeading={true} name={"Created at"}
                                                     value={formatTimestampToDateTime(trace.getCreatedAt())}/>
                                {
                                    trace.getLastFinishedTraceDate() === undefined &&
                                    <ListGroupWidgetItem widgetClass={""} noSubHeading={true} value={<span>&nbsp;</span>}/>
                                }
                                {
                                    trace.getLastFinishedTraceDate() !== undefined &&
                                    <ListGroupWidgetItem widgetClass={""} noSubHeading={true} name={"Last finished trace date"}
                                                         value={formatTimestampToDateTime(trace.getLastFinishedTraceDate())}/>
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                </Card>
            </div>

            <Card className="mb-3" tabs={true}>
                <CardHeader className="card-header-tab">
                    <Nav>
                        <NavItem>
                            <NavLink
                                className={classnames({active: activeTab === 'overview'})}
                                onClick={() => {
                                    router.push(trace.linkToOverview() + "?tab=overview")
                                }}
                            >
                                Overview
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: activeTab === 'dataissues'})}
                                onClick={() => {
                                    router.push(trace.linkToOverview() + "?tab=dataissues")
                                }}
                            >
                                Data issues
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: activeTab === 'details'})}
                                onClick={() => {
                                    router.push(trace.linkToOverview() + "?tab=details")
                                }}
                            >
                                Tracing details
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="overview">
                            <TraceStats trace={trace}/>
                            {
                                trace.getLogs().length === 0 && !trace.hasInternalFailures() && !trace.hasFailures() &&
                                <Alert color="success">
                                    All good, no issues found!
                                </Alert>
                            }
                            {
                                trace.hasInternalFailures() &&
                                <Alert color="danger">
                                    <h4>Sorry!</h4>
                                    Internal failure have been detected. We are already notified about it and are
                                    working on the
                                    fix. <br/>
                                    This trace attempt will stay broken. Fix will affect only new scrapes.
                                </Alert>
                            }
                            {
                                trace.hasFailures() &&
                                <Alert color="warning">
                                    <h4>Failures have been detected!</h4>
                                    Please check <Link to={trace.linkToOverview() + "?tab=details&result=Failed"}>tracing
                                    details</Link>.
                                </Alert>
                            }
                            {
                                trace.wasRateLimited() &&
                                <Alert color="info">
                                    <h4>Processing was rate limited</h4>
                                    Rate limit is a protective measure imposed by Github. <br />
                                    If your library processing ends in a reasonable time this warning is nothing to worry about.<br />
                                    For the further investigation you can check <Link to={trace.linkToOverview() + "?tab=dataissues&type=rateLimited"}>data
                                    issues</Link>.
                                </Alert>
                            }
                            {
                                trace.getLogs(true).length !== 0 &&
                                <Alert color="warning">
                                    <h4>Data issues have been detected!</h4>
                                    Please check <Link to={trace.linkToOverview() + "?tab=dataissues"}>data
                                    issues</Link>.
                                </Alert>
                            }
                        </TabPane>
                        <TabPane tabId="dataissues">
                            <ReactTable
                                data={Object.values(trace.getLogs()).map(x => x.data)}
                                filterable
                                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                                columns={[
                                    {
                                        Header: "Data issues",
                                        columns: [
                                            {
                                                Header: "Type",
                                                accessor: "Type",
                                                width: 200
                                            },
                                            {
                                                Header: "Repository path",
                                                accessor: "ID"
                                            },
                                            // {
                                            //     Header: "Level",
                                            //     accessor: "Level"
                                            // },
                                            {
                                                Header: "Message",
                                                accessor: "Message"
                                            }
                                        ]
                                    }
                                ]}
                                defaultPageSize={10}
                                className="-striped -highlight"
                            />
                        </TabPane>
                        <TabPane tabId="details">
                            <ReactTable
                                data={Object.values(trace.getEntries()).map(x => x.data)}
                                filterable
                                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                                defaultFiltered={[
                                    {
                                        "id": "Result",
                                        "value": query.get("result") || ""
                                    }
                                ]}
                                columns={[
                                    {
                                        Header: "Traces",
                                        columns: [
                                            {
                                                Header: "Repo",
                                                accessor: "Repo",
                                            },
                                            {
                                                Header: "Plugin",
                                                accessor: "Plugin"
                                            },
                                            {
                                                Header: "Result",
                                                accessor: "Result"
                                            },
                                            {
                                                Header: "Details",
                                                accessor: "Details"
                                            },
                                            {
                                                Header: "ScheduledAt",
                                                accessor: "ScheduledAt",
                                                Cell: row => formatTimestampToDateTime(row.value)
                                            },
                                            {
                                                Header: "FinishedAt",
                                                accessor: "FinishedAt",
                                                Cell: row => {
                                                    return row.value !== "" ? formatTimestampToDateTime(row.value) : ""
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                defaultPageSize={20}
                                className="-striped -highlight"
                            />
                        </TabPane>
                    </TabContent>

                </CardBody>

            </Card>
        </Container>
    </>;
}

function TraceStats({trace}) {
    const traceStats = useMemo(() => {
        const stats = {};

        Object.values(trace.getEntries()).forEach(x => {
            if (stats[x.data.Result] === undefined) {
                stats[x.data.Result] = 0;
            }
            stats[x.data.Result]++;
        });

        return stats;
    }, [trace]);

    return <>
        <h4>Trace summary</h4>
        <ul>
            {Object.keys(traceStats).sort().map(x => {
                return <li>
                    {
                        x === "" &&
                        <>Not processed (yet): {traceStats[x]}</>
                    }
                    {
                        x !== "" &&
                        <><Link to={trace.linkToOverview() + "?tab=details&result=" + x}>{x}</Link>: {traceStats[x]}</>
                    }
                </li>
            })}
        </ul>
    </>
}