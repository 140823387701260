import _ from "lodash";

export default class Team {

    org = {};
    isLoaded = false;

    constructor(org, data) {
        this.org = org;
        this.setData(data);
    }

    setData(data) {
        const defaultData = {
            Name: "",
            Mission: "",
            Lead: "",
            Members: [],
            Contacts: []
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        this.data = newData;
    }

    getData() {
        return this.data;
    }

    getId() {
        return this.data['Name'];
    }

    linkToEdit() {
        return "/" + this.org.getId() + "/teams/" + this.getId();
    }

    save() {
        return this.org.saveTeam(this);
    }

    delete() {
        return this.org.deleteTeam(this);
    }
}