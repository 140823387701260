import React from 'react';
import PageTitle from "../Layout/PageTitle";
import {Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import {Link} from "react-router-dom";

export default function Dashboard({org, doxyURL}) {
    return <>
        <PageTitle
            heading={org.getName()}
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        >
            <a href={doxyURL + org.getId()}>Go to Service360 &gt;&gt;&gt;</a>
        </PageTitle>
        <Container fluid>
            <LibrariesOverview org={org} doxyURL={doxyURL}/>
            <Row>
                <Col md={6}>
                    <UsersOverview org={org}/>
                </Col>
                <Col md={6}>
                    <BillingOverview org={org}/>
                </Col>
            </Row>

        </Container>
    </>
}

function LibrariesOverview({org, doxyURL}) {
    if (Object.values(org.getLibs()).length === 0) {
        return null;
    }

    return <Row className={"mb-3"}>
        {Object.values(org.getLibs()).map(lib => {
            return <Col md={4} key={"libOverview" + lib.getId()}>
                <LibraryOverview library={lib} doxyURL={doxyURL}/>
            </Col>
        })}
    </Row>;
}

function ListGroupWidgetItem({name, subheading, value}) {
    return <ListGroupItem>
        <div className="widget-content p-0">
            <div className="widget-content-wrapper">
                <div className="widget-content-left">
                    <div className="widget-heading">
                        {name}
                    </div>
                    {
                        subheading !== undefined &&
                        <div className="widget-subheading">
                            {subheading}
                        </div>
                    }
                </div>

                {
                    value !== undefined &&
                    <div className="widget-content-right">
                        <div className="widget-numbers text-info">
                            {value}
                        </div>
                    </div>
                }
            </div>
        </div>
    </ListGroupItem>
}

function LibraryOverview({library, doxyURL}) {
    const overview = library.getOverview();

    return <Card>
        <CardHeader>
            <Link to={library.linkToOverview()}>{library.getName()}</Link>
            <div className="btn-actions-pane-right actions-icon-btn">
                <a href={doxyURL + library.org.getId() + "/" + library.getId()}>Service360 View</a>
            </div>
        </CardHeader>
        <CardBody>
            <ListGroup flush>
                <ListGroupWidgetItem name={"Sources"} value={library.getSources().length}/>
                {
                    overview && overview.servicesCount !== undefined &&
                    <>
                        <ListGroupWidgetItem name={"Services"} value={overview.servicesCount}/>
                        <ListGroupWidgetItem name={"Contributors"} value={overview.contributorsCount}/>
                        <ListGroupWidgetItem name={"Services owners"} value={overview.owners.length}/>
                        <ListGroupWidgetItem name={"Contexts"} value={overview.contexts.length}/>
                        <ListGroupWidgetItem name={"Last scraped"}
                                             subheading={overview.updatedAt.slice(0, 19).replace("T", " ")}/>
                    </>
                }
            </ListGroup>
        </CardBody>
    </Card>;
}

function UsersOverview({org}) {
    return <Card className="mb-3">
        <CardHeader>
            <Link to={org.linkToUsers()}>Users</Link>
        </CardHeader>
        <CardBody>
            <ListGroup flush>
                <ListGroupWidgetItem name={"Total users"} value={Object.values(org.getUsers()).length}/>
            </ListGroup>
        </CardBody>
    </Card>
}

function BillingOverview({org}) {
    const currentDate = new Date();
    const previousMonth = new Date();
    previousMonth.setDate(0);

    const currentBullingPeriod = (currentDate).getFullYear() + "-" + ((currentDate).getMonth() + 1).toString().padStart(2, "0");
    const previousBullingPeriod = (previousMonth).getFullYear() + "-" + ((previousMonth).getMonth() + 1).toString().padStart(2, "0");

    const currentInvoice = org.getBillingOverview()[currentBullingPeriod];
    const previousInvoice = org.getBillingOverview()[previousBullingPeriod];

    return <Card className="mb-3">
        <CardHeader>
            <Link to={org.linkToBilling()}>Billing</Link>
        </CardHeader>
        <CardBody>
            <ListGroup flush>
                <ListGroupItem>
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <div className="widget-heading">
                                    Current month ({currentBullingPeriod})
                                </div>
                                <div className="widget-subheading">
                                    Repositories:
                                    {
                                        currentInvoice &&
                                        <span>{currentInvoice.data.Repos.length}</span>
                                    }
                                    {
                                        !currentInvoice && "0"
                                    }
                                </div>
                            </div>

                            <div className="widget-content-right">
                                <div className="widget-numbers text-info">
                                    {
                                        currentInvoice &&
                                        <>{currentInvoice.billableAmount()} euro</>
                                    }
                                    {
                                        !currentInvoice &&
                                        <>0 euro</>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </ListGroupItem>
                {
                    previousInvoice &&
                    <ListGroupWidgetItem
                        name={"Previous month (" + previousBullingPeriod + ")"}
                        subheading={"Repositories: " + previousInvoice.data.Repos.length}
                        value={previousInvoice.billableAmount() + "  euro"}/>
                }
            </ListGroup>
        </CardBody>
    </Card>
}