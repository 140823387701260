import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Source from "../Source";
import NotFound from "../Layout/NotFound";
import NewLibrary from "./components/NewLibrary";
import LibrarySources from "./components/LibrarySources";
import TechRadar from "./components/TechRadar";
import usePlugins from "../../hooks/usePlugins";
import PluginsOverview from "./components/PluginsOverview";
import PageTitle from "../Layout/PageTitle";
import Plugin from "./components/Plugin";

export default function Library({lib, doxyURL, technoURL}) {
    const plugins = usePlugins();

    return <>
    <Switch>
        <Route exact path={lib.org.linkToLibraries() + "/+"} render={() => {
            return <NewLibrary lib={lib}/>
        }}/>

        <Route exact path={[lib.linkToOverview(), lib.linkToSources()]} render={() => {
            return <>
                <PageTitle
                    heading={"Library: " + lib.getName()}
                    icon="pe-7s-car icon-gradient bg-mean-fruit"
                />
                <LibrarySources lib={lib} doxyURL={doxyURL}/>
            </>
        }}/>

        <Route exact path={lib.linkToTechRadarConfig()} render={() => {
            return <>
                <PageTitle
                    heading={"Library: " + lib.getName()}
                    icon="pe-7s-car icon-gradient bg-mean-fruit"
                />
                <TechRadar lib={lib} technoURL={technoURL}/>
            </>
        }}/>

        <Route exact path={lib.linkToPlugins()} render={() => {
            return <>
                <PageTitle
                    heading={"Library: " + lib.getName() + " | Plugins"}
                    icon="pe-7s-car icon-gradient bg-mean-fruit"
                />
                <PluginsOverview library={lib}/>
            </>
        }}/>

        <Route exact path={lib.linkToPluginConfig() + "/:pluginType"} render={(route) => {
            const pluginType = route.match.params.pluginType;

            if (!plugins.hasPlugin(pluginType)) {
                return <NotFound text={"Plugin " + pluginType + " not found"}/>
            }

            return <>
                <PageTitle
                    heading={"Library: " + lib.getName()}
                    icon="pe-7s-car icon-gradient bg-mean-fruit"
                />
                <Plugin library={lib} pluginType={pluginType}/>
            </>
        }}/>

    <Route exact path={lib.linkToSources() + "/+"} render={(route) => {
        const source = lib.newSource({
            Name: "New Source",
            RepoType: "github"
        });

        return <Source source={source} isNew={true}/>
    }}/>

    <Route path={lib.linkToSources() + "/:source"} render={(route) => {
        let sourceName = route.location.pathname.substr(lib.linkToSources().length + 1);

        const isClone = sourceName.endsWith("/clone"); // all this shit is because we allow "/" in the source name
        if (isClone) {
            sourceName = sourceName.substr(0, sourceName.length - 6)
        }

        const originalSource = lib.getSources().find(x => x.getName() === sourceName);
        if (!originalSource) {
            return <NotFound text={"Source not found"}/>
        }

        if (isClone) {
            const source = lib.newSource(originalSource.getData());
            source.setData({
                Name: "Clone " + originalSource.getName()
            });
            return <Source source={source} isNew={true}/>
        }

        return <Source source={originalSource}/>
    }}/>
    </Switch>
</>
}
