import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Collapse,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {DropdownList} from 'react-widgets'
import {Redirect} from "react-router-dom";
import {useInput} from "../../hooks/useInput";
import {RepoType_Github, SourceType_Mono, SourceType_Org, SourceType_Single} from "../../domain/source";
import cx from "classnames";
import {Loader} from "react-loaders";
import SweetAlert from "sweetalert-react";
import BlockUi from "react-block-ui";
import PageTitle from "../Layout/PageTitle";
import {useLoader} from "../../hooks/useLoader";
import DualListBox from "react-dual-listbox";

import 'react-dual-listbox/lib/react-dual-listbox.css';
import {HelpTooltip} from "../Layout/Tooltip";

function PluginAccordeonCard({name, id, children, enabled, onChange, showSwitch = true, initialExpanded = false}) {
    const [expanded, setExpanded] = useState(initialExpanded);

    return <Card>
        <CardHeader id={id}>
            <Button block color="link" className="text-left m-0 p-0"
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-controls={"collapse" + id}>

                <h5 className="m-0 p-0">
                    {children && expanded && <i className="lnr-chevron-down"/>}
                    {children && !expanded && <i className="lnr-chevron-right"/>} {name}</h5>
            </Button>
            {
                showSwitch &&
                <div className="switch has-switch mb-2 ml-2" data-on-label="ON"
                     data-off-label="OFF"
                     onClick={
                         (event) => {
                             setExpanded(!enabled);
                             onChange()
                         }
                     }>
                    <div className={cx("switch-animate", {
                        'switch-on': enabled,
                        'switch-off': !enabled
                    })}>
                        <input type="checkbox"/><span
                        className="switch-left">ON</span><label>&nbsp;</label><span
                        className="switch-right">OFF</span>
                    </div>
                </div>
            }
        </CardHeader>
        {
            children &&
            <Collapse isOpen={expanded} data-parent="#plugins"
                      id={"collapse" + id} aria-labelledby={id}>
                <CardBody>
                    <BlockUi tag="div" blocking={!enabled}>
                        {children}
                    </BlockUi>
                </CardBody>
            </Collapse>
        }
    </Card>
}

function PassportPlugin({Folder, Filename, onChange}) {
    const [folder, setFolder] = useState(Folder);
    const [filename, setFilename] = useState(Filename);

    return <PluginAccordeonCard id={"passport"} name={"Passport"} enabled={true} showSwitch={false}
                                initialExpanded={true}>
        <FormGroup inline>
            <Label>Folder</Label>
            <Input type="text" value={folder} onChange={(event) => {
                const value = event.target.value;
                setFolder(value);
                onChange(value, filename)
            }}/>
        </FormGroup>

        <FormGroup inline>
            <Label>Filename</Label>
            <Input type="text" value={filename} onChange={(event) => {
                const value = event.target.value;
                setFilename(value);
                onChange(folder, value)
            }}/>
        </FormGroup>
    </PluginAccordeonCard>
}

function PlantUMLPlugin({Enabled, Folder, Filename, onChange, isNew}) {
    const [enabled, setEnabled] = useState(Enabled);
    const [folder, setFolder] = useState(Folder);
    const [filename, setFilename] = useState(Filename);

    const toggleEnable = () => {
        setEnabled(!enabled);
        onChange(!enabled, folder, filename)
    };

    return <PluginAccordeonCard id={"puml"} name={"Service Dependency Graph"} enabled={enabled} initialExpanded={isNew}
                                onChange={toggleEnable}>
        <FormGroup>
            <Label>Folder</Label>
            <Input type="text" value={folder} onChange={(event) => {
                const value = event.target.value;
                setFolder(value);
                onChange(enabled, value, filename)
            }}/>
        </FormGroup>

        <FormGroup>
            <Label>Filename</Label>
            <Input type="text" value={filename} onChange={(event) => {
                const value = event.target.value;
                setFilename(value);
                onChange(enabled, folder, value)
            }}/>
        </FormGroup>
    </PluginAccordeonCard>
}

function APIPlugin({Enabled, Folder, Filename, onChange, isNew}) {
    const [enabled, setEnabled] = useState(Enabled);
    const [folder, setFolder] = useState(Folder);
    const [filename, setFilename] = useState(Filename);

    const toggleEnable = () => {
        setEnabled(!enabled);
        onChange(!enabled, folder, filename)
    };

    return <PluginAccordeonCard id={"api"} name={"API"} enabled={enabled} onChange={toggleEnable}
                                initialExpanded={isNew}>
        <FormGroup>
            <Label>Folder</Label>
            <Input type="text" value={folder} onChange={(event) => {
                const value = event.target.value;
                setFolder(value);
                onChange(enabled, value, filename)
            }}/>
        </FormGroup>

        <FormGroup>
            <Label>Filename</Label>
            <Input type="text" value={filename} onChange={(event) => {
                const value = event.target.value;
                setFilename(value);
                onChange(enabled, folder, value)
            }}/>
        </FormGroup>
    </PluginAccordeonCard>
}

function DocsPlugin({Enabled, Folder, onChange, isNew}) {
    const [enabled, setEnabled] = useState(Enabled);
    const [folder, setFolder] = useState(Folder);

    const toggleEnable = () => {
        setEnabled(!enabled);
        onChange(!enabled, folder);
    };

    return <PluginAccordeonCard id={"docs"} name={"Docs"} enabled={enabled} onChange={toggleEnable}
                                initialExpanded={isNew}>
        <FormGroup>
            <Label>Folder</Label>
            <Input type="text" value={folder} onChange={(event) => {
                const value = event.target.value;
                setFolder(value);
                onChange(enabled, value)
            }}/>
        </FormGroup>
    </PluginAccordeonCard>
}

function ReleasesPlugin({Enabled, ReleasesParseMethod, onChange, isNew}) {
    const [enabled, setEnabled] = useState(Enabled);
    const [releasesParseMethod, setReleasesParseMethod] = useState(ReleasesParseMethod);

    const toggleEnable = () => {
        setEnabled(!enabled);
        onChange(!enabled, releasesParseMethod)
    };

    return <PluginAccordeonCard id={"releases"} name={"Releases"} enabled={enabled} onChange={toggleEnable}
                                initialExpanded={isNew}>

        <FormGroup check>
            <Label check>
                <Input type="radio" checked={releasesParseMethod !== "masterCommits"} name="releases" onClick={
                    (event) => {
                        setReleasesParseMethod("githubReleases");
                        onChange(enabled, "githubReleases")
                    }
                }/>{' '}
                Application release = Github Release
            </Label>
        </FormGroup>
        <FormGroup check>
            <Label check>
                <Input type="radio" name="releases" checked={releasesParseMethod === "masterCommits"} onClick={
                    (event) => {
                        setReleasesParseMethod("masterCommits");
                        onChange(enabled, "masterCommits")
                    }
                }/>{' '}
                Application release = Master branch commit
            </Label>
        </FormGroup>
    </PluginAccordeonCard>
}

function ContributorsPlugin({Enabled, onChange}) {
    const [enabled, setEnabled] = useState(Enabled);

    const toggleEnable = () => {
        setEnabled(!enabled);
        onChange(!enabled)
    };

    return <PluginAccordeonCard id={"contributors"} name={"Contributors"} enabled={enabled} onChange={toggleEnable}/>
}

export default function Source({source, isNew = false}) {
    const {value: name, bind: bindName} = useInput(source.getName());
    const {value: sourceType, setValue: setSourceType} = useInput(source.getSourceType());
    const {value: githubRepoOwner, bind: bindGithubRepoOwner} = useInput(source.getGithubRepoParams().Owner);
    const {value: githubSkipForks, setValue: setGithubSkipForks} = useInput(source.getGithubRepoParams().SkipForks);
    const {value: githubRepo, bind: bindGithubRepo} = useInput(source.getGithubRepoParams().Repo);
    const {value: githubToken, bind: bindGithubToken} = useInput(source.getGithubRepoParams().Token);
    const {value: blacklist, setValue: setBlacklist} = useInput(source.getBlacklist());

    const [plugins, setPlugins] = useState({});

    const [persistenceResult, setPersistenceResult] = useState({
        isLoading: false,
        redirectAfterSave: false,
        error: false
    });

    const {isLoading, redirectAfterSave, error} = persistenceResult;

    const handleSubmit = async (evt) => {
        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        source.setData({
            Name: name,
            RepoType: RepoType_Github,
            SourceType: sourceType,
            GithubRepoParams: {
                Owner: githubRepoOwner,
                Repo: githubRepo,
                Token: githubToken,
                SkipForks: githubSkipForks
            },
            Blacklist: blacklist,
            Plugins: plugins
        });

        try {
            await source.save();
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                redirectAfterSave: true
            });
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };

    const handleDelete = async (evt) => {
        if (window.confirm("Are you sure want to delete source?")) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: true
            });
            try {
                await source.delete();
                setPersistenceResult({
                    ...persistenceResult,
                    isLoading: false,
                    redirectAfterSave: true
                });
            } catch (e) {
                setPersistenceResult({
                    ...persistenceResult,
                    isLoading: false,
                    error: e
                });
            }
        }
    };


    if (redirectAfterSave) {
        return <Redirect to={source.lib.linkToOverview()}/>
    }

    return <>
        <PageTitle
            heading={"Source: " + source.getName()}
            linkBack={source.lib.linkToOverview()}
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        />
        <BlockUi tag="div" blocking={isLoading}
                 message={"Saving"}
                 loader={<Loader active type="ball-pulse"/>}>
            <SweetAlert
                title="Error"
                confirmButtonColor=""
                show={error !== false}
                text={error ? error.message + "\n\n" + error.response.data : ""}
                confirmButtonText="Okay"
                onConfirm={() => {
                    setPersistenceResult({
                        ...persistenceResult,
                        error: false
                    });
                }}
                type="error"/>

            <Row>
                <Col md={6}>
                    <Card className="mb-3">
                        <CardBody>
                            <FormGroup>
                                <Label>Source name</Label>
                                <Input type="text" name="name" id="name" {...bindName}
                                       validate={{
                                           required: {
                                               value: true,
                                               errorMessage: 'Please enter a source name'
                                           },
                                           pattern: {
                                               value: "^[^/]+$",
                                               errorMessage: 'Source name should not contain /'
                                           }
                                       }}
                                       placeholder="Source name" disabled={!isNew}/>
                            </FormGroup>

                            <FormGroup>
                                <Label>Repository type</Label>
                                <Input type="text" value={RepoType_Github} name="repoType" disabled={true}/>
                            </FormGroup>

                            <FormGroup>
                                <Label>Source repository type</Label>
                                <DropdownList
                                    data={[
                                        {
                                            value: SourceType_Org,
                                            text: "Organisation (one organisation - multiple single repos)"
                                        },
                                        {
                                            value: SourceType_Single,
                                            text: "Single repository (one repo - one app)"
                                        },
                                        {
                                            value: SourceType_Mono,
                                            text: "Mono repository (one repo - multiple apps)"
                                        }
                                    ]}

                                    value={sourceType}
                                    onSelect={(v) => setSourceType(v.value)}
                                    textField="text"
                                    valueField="value"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>Github repository owner/organisation</Label>
                                <Input type="text" {...bindGithubRepoOwner}/>
                            </FormGroup>

                            {
                                sourceType !== SourceType_Org &&
                                <FormGroup>
                                    <Label>Github repository</Label>
                                    <Input type="text" {...bindGithubRepo}/>
                                </FormGroup>
                            }

                            <FormGroup>
                                <Label>Github access token (<a href="https://docs.github.com/en/github/authenticating-to-github/creating-a-personal-access-token">How
                                    to create access token?</a>)</Label>
                                <Input type="password" {...bindGithubToken}/>
                            </FormGroup>

                            {
                                sourceType === SourceType_Org &&
                                <FormGroup inline>
                                    <div className="form-check-inline">
                                        <Label>Skip forked repositories&nbsp;<HelpTooltip
                                            tip={"Forked repositories show all people ever contributed to the repo as team contributors"}/></Label>
                                        <div className="switch has-switch mb-2 ml-2" data-on-label="ON"
                                             data-off-label="OFF"
                                             onClick={
                                                 (event) => {
                                                     setGithubSkipForks(!githubSkipForks);
                                                 }
                                             }>
                                            <div className={cx("switch-animate", {
                                                'switch-on': githubSkipForks,
                                                'switch-off': !githubSkipForks
                                            })}>
                                                <input type="checkbox"/><span
                                                className="switch-left">ON</span><label>&nbsp;</label><span
                                                className="switch-right">OFF</span>
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                            }

                            {
                                sourceType === SourceType_Org &&
                                <Blacklist blacklist={blacklist}
                                           setBlacklist={setBlacklist}
                                           token={githubToken}
                                           owner={githubRepoOwner}/>
                            }

                            <ConnectionCheck token={githubToken} owner={githubRepoOwner} repository={githubRepo}
                                             isOrg={sourceType === SourceType_Org}/>

                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <div id="plugins" className="accordion-wrapper mb-3">
                        <PassportPlugin{...source.getPassportPlugin()}
                                       onChange={(folder, filename) => {
                                           plugins.Passport = {
                                               Folder: folder,
                                               Filename: filename,
                                           };
                                           setPlugins(plugins);
                                       }}/>

                        <PlantUMLPlugin {...source.getPlantUMLPlugin()} isNew={isNew}
                                        onChange={(enabled, folder, filename) => {
                                            plugins.PlantUML = {
                                                Enabled: enabled,
                                                Folder: folder,
                                                Filename: filename
                                            };
                                            setPlugins(plugins);
                                        }}/>

                        <DocsPlugin {...source.getDocsPlugin()} isNew={isNew} onChange={(enabled, folder) => {
                            plugins.Docs = {
                                Enabled: enabled,
                                Folder: folder
                            };
                            setPlugins(plugins);
                        }}/>

                        <APIPlugin {...source.getAPIPlugin()} isNew={isNew} onChange={(enabled, folder, filename) => {
                            plugins.API = {
                                Enabled: enabled,
                                Folder: folder,
                                Filename: filename
                            };
                            setPlugins(plugins);
                        }}/>

                        <ReleasesPlugin {...source.getReleasesPlugin()} isNew={isNew}
                                        onChange={(enabled, releasesParseMethod) => {
                                            if (enabled) {
                                                plugins.Releases = {
                                                    Enabled: enabled,
                                                    ReleasesParseMethod: releasesParseMethod
                                                };
                                            } else {
                                                plugins.Releases = {
                                                    Enabled: enabled
                                                };
                                            }
                                            setPlugins(plugins);
                                        }}/>

                        <ContributorsPlugin {...source.getContributorsPlugin()} onChange={(enabled) => {
                            plugins.Contributors = {
                                Enabled: enabled,
                            };
                            setPlugins(plugins);
                        }}/>

                    </div>
                </Col>
            </Row>


            <CardFooter>
                <Button color="primary" onClick={handleSubmit}>Save</Button>
                {
                    !isNew &&
                    <Button color="danger" className="ml-1" onClick={handleDelete}>Delete</Button>
                }
            </CardFooter>

        </BlockUi>
    </>
}

function Blacklist({token, owner, setBlacklist, blacklist}) {
    const {adminLoader} = useLoader();
    const [options, setOptions] = useState(blacklist.filter(x => x !== "").map(x => {
        return {value: x, label: x}
    }));

    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState("Loading repo list");

    useEffect(() => {
        setLoadingMessage("Loading repo list");
        setLoading(true);

        const load = async () => {
            try {
                const res = await adminLoader.post("/check/repos/github", {
                    Token: token,
                    RepoOwner: owner
                });
                setOptions(res.data.sort((a, b) => a > b ? 1 : -1).map(x => {
                    return {value: x, label: x}
                }));
                setLoading(false);
            } catch (e) {
                setLoadingMessage("Failed to load repo list. Check connection settings.: " + e)
            }
        };
        load();
    }, [token, owner]);

    return <BlockUi blocking={loading} message={loadingMessage}>
        <FormGroup>
            <Label>Blacklist</Label>

            <DualListBox
                canFilter
                showOrderButtons={false}
                showNoOptionsText={true}
                showHeaderLabels={true}
                icons={{
                    moveLeft: <span className="lnr-arrow-left"/>,
                    moveAllLeft: [
                        <span key={0} className="lnr-chevron-left"/>,
                        <span key={1} className="lnr-chevron-left"/>,
                    ],
                    moveRight: <span className="lnr-arrow-right"/>,
                    moveAllRight: [
                        <span key={0} className="lnr-chevron-right"/>,
                        <span key={1} className="lnr-chevron-right"/>,
                    ],
                }}
                options={options}
                selected={blacklist}
                onChange={setBlacklist}
            />
        </FormGroup>
    </BlockUi>
}

export function ConnectionCheck({token, repository, owner, isOrg = true}) {
    const {adminLoader} = useLoader();
    const [isLoading, setIsLoading] = useState(false);
    const [checkState, setCheckState] = useState(false);

    const check = async () => {
        setIsLoading(true);

        const data = {
            Token: token,
            RepoOwner: owner
        };

        if (!isOrg) {
            data.RepoName = repository
        }

        try {
            const res = await adminLoader.post("/check/connection/github", data);
            setCheckState(res.data);
        } catch (e) {
            if (e.message) {
                setCheckState(e.message);
            } else {
                setCheckState(JSON.stringify(e));
            }
        }
        setIsLoading(false);
    };

    return <BlockUi blocking={isLoading}>
        {
            checkState && checkState === "OK" &&
            <Alert color={"success"}>
                <h4 className="alert-heading">All good!</h4>
                Connection tested successfully
            </Alert>
        }
        {
            checkState && checkState !== "OK" &&
            <Alert color={"danger"}>
                <h4 className="alert-heading">Failed!</h4>
                {checkState}
            </Alert>
        }
        <Button onClick={check} color="primary">Test
            connection</Button>
    </BlockUi>
}