import _ from "lodash";
import TraceEntry from "./traceentry";
import ScrapeLog from "./scrapelog";

const logType_RateLimit = "rateLimited"

export default class Trace {

    org = {};
    isLoaded = false;

    constructor(org, loader, data) {
        this.org = org;
        this.loader = loader;
        this.entries = [];
        this.logs = [];
        this.progress = {
            Progress: 0,
            Finished: 0,
            Total: 0
        };
        this.setData(data);
    }

    async ready() {
        const traceEntryData = await this.loader.get("/orgs/" + this.org.getId() + "/traces/" + this.getId());
        this.entries = [];
        traceEntryData.data.forEach((entry) => {
            this.entries.push(new TraceEntry(this, entry));
        });

        const logData = await this.loader.get("/orgs/" + this.org.getId() + "/traces/" + this.getId() + "/logs");
        this.logs = [];
        logData.data.forEach((entry) => {
            this.logs.push(new ScrapeLog(this, entry));
        });

        const progress = await this.loader.get("/orgs/" + this.org.getId() + "/traces/" + this.getId() + "/progress");
        this.progress = progress.data;

        this.isLoaded = true;
    }

    setData(data) {
        const defaultData = {
            ID: "",
            CreatedAt: "",
            Initiator: "",
            TargetType: "",
            Target: ""
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        this.data = newData;
    }

    getProgress() {
        return this.progress;
    }

    getEntries() {
        return this.entries;
    }

    getLogs(withoutRateLimit = false) {
        if (withoutRateLimit) {
            return this.logs.filter(x => x.data.Type !== logType_RateLimit)
        }

        return this.logs;
    }

    wasRateLimited() {
        return this.logs.find(x => x.data.Type === logType_RateLimit) !== undefined;
    }

    hasInternalFailures() {
        return this.getEntries().find(x => x.data.Result === "Failed/Internal") !== undefined;
    }

    hasFailures() {
        return this.getEntries().find(x => x.data.Result === "Failed") !== undefined;
    }

    getId() {
        return this.data['ID'];
    }

    getData() {
        return this.data;
    }

    getCreatedAt() {
        return new Date(this.getData().CreatedAt / 1000000)
    }

    getLastFinishedTraceDate() {
        const sortedByFinishedAt = this.getEntries()
            .filter(x => x.data.FinishedAt !== "")
            .sort((a, b) => a.data.FinishedAt > b.data.FinishedAt ? -1 : 1);
        if (sortedByFinishedAt.length > 0) {
            return new Date(sortedByFinishedAt[0].data.FinishedAt * 1000)
        }

        return;
    }

    linkToOverview() {
        return this.org.linkToTraces() + "/" + this.getId();
    }
}