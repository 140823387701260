import React from 'react';
import PageTitle from "../Layout/PageTitle";
import {Card, CardHeader, Container} from "reactstrap";
import {Link} from "react-router-dom";

export default function Libraries({org}) {
    return <>
        <PageTitle
            heading="Libraries"
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        />
        <Container fluid>
            {
                Object.values(org.getLibs()).map((lib, i) => {
                    return (
                        <Card key={lib.getId()}>
                            <CardHeader>
                                <Link to={lib.linkToOverview()}>{lib.getName()}</Link>
                            </CardHeader>
                        </Card>
                    )
                })
            }
            <Card>
                <CardHeader>
                    <Link to={org.linkToLibraries() + "/+"}>ADD NEW LIBRARY</Link>
                </CardHeader>
            </Card>
        </Container>
    </>;
}