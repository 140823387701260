import React, {useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {Redirect} from "react-router-dom";
import {useInput} from "../../../hooks/useInput";
import cx from "classnames";
import {Loader} from "react-loaders";
import SweetAlert from "sweetalert-react";
import BlockUi from "react-block-ui";
import {HelpTooltip} from "../../Layout/Tooltip";
import {GithubProfileType} from "../../../domain/orguser";
import {isValidEmail} from "../../../utils/validation";
import {Contacts} from "./Contacts";

export default function User({user, isNew = false, onSave}) {
    const {value: name, bind: bindName} = useInput(user.getName());
    const {value: info, bind: bindInfo} = useInput(user.getInfo());
    const {value: email, bind: bindEmail} = useInput(user.getId());
    const [contacts, setContacts] = useState(user.getContacts());
    const {value: isAdmin, setValue: setIsAdmin} = useInput(user.isAdmin());
    const {value: ghProfiles, bind: bindGhProfiles} = useInput(user.getProfiles(GithubProfileType).join(','));

    const [persistenceResult, setPersistenceResult] = useState({
        isLoading: false,
        redirectAfterSave: false,
        isDeleted: false,
        error: false
    });

    const {isLoading, redirectAfterSave, isDeleted, error} = persistenceResult;

    const handleSubmit = async (evt) => {
        const profiles = {}
        profiles[GithubProfileType] = ghProfiles.split(",").map(x => x.trim(" "))

        user.setData({
            Name: name,
            Info: info,
            Contacts: contacts,
            EMail: email,
            IsAdmin: isAdmin,
            Profiles: profiles
        });
        //Merge spoils everything
        user.data.Profiles = profiles;
        user.data.Contacts = contacts;

        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        try {
            await user.save();
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                redirectAfterSave: true
            });
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };

    const handleDelete = async (evt) => {
        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        try {
            await user.delete();
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                isDeleted: true
            });
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };


    if (isDeleted || redirectAfterSave) {
        if (onSave) {
            return onSave()
        } else {
            return <Redirect to={user.org.linkToUsers()}/>;
        }
    }

    return <>
        <Container fluid className={"p-0"}>
            <BlockUi tag="div" blocking={isLoading}
                     message={"Saving"}
                     loader={<Loader active type="ball-pulse"/>}>
                <SweetAlert
                    title="Error"
                    confirmButtonColor=""
                    show={error !== false}
                    text={error ? error.message + "\n\n" + error.response.data : ""}
                    confirmButtonText="Okay"
                    onConfirm={() => {
                        setPersistenceResult({
                            ...persistenceResult,
                            error: false
                        });
                    }}
                    type="error"/>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Email *</Label>
                                    <Input type="email"  {...bindEmail} invalid={!isValidEmail(email)}
                                           placeholder="email"/>
                                    <FormFeedback valid={isValidEmail(email)}>Please provide a valid email address</FormFeedback>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label>Is admin</Label>
                                    <div className="switch has-switch mb-2 ml-2" data-on-label="ON"
                                         data-off-label="OFF"
                                         onClick={
                                             (event) => {
                                                 setIsAdmin(!isAdmin);
                                             }
                                         }>
                                        <div className={cx("switch-animate", {
                                            'switch-on': isAdmin,
                                            'switch-off': !isAdmin
                                        })}>
                                            <input type="checkbox"/><span
                                            className="switch-left">ON</span><label>&nbsp;</label><span
                                            className="switch-right">OFF</span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type="text"  {...bindName}
                                           placeholder="Name"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Contacts</Label>
                                    <Contacts contacts={contacts} setContacts={setContacts}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Linked Github profiles <HelpTooltip tip={"Comma-separated list of github logins"}/></Label>
                                    <Input type="text"  {...bindGhProfiles}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Public info</Label>
                                    <Input type="textarea"  {...bindInfo} 
                                           placeholder="info"/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Button color="primary" onClick={handleSubmit} disabled={!isValidEmail(email)}>Save</Button>
                        {
                            !isNew &&
                            <Button color="danger" className="ml-1" onClick={() => {
                                if (window.confirm("Are you sure want to remove a user from the organisation?")) {
                                    handleDelete()
                                }
                            }}>Delete</Button>
                        }
                    </CardFooter>
                </Card>
            </BlockUi>
        </Container>
    </>
}
