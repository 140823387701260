class PluginManager {
    events = null

    constructor() {
        this.types = {};
    }

    deregisterAll() {
        this.types = {};
    }

    registerPlugin({type, name, description, render, beta, image}) {
        this.types[type] = {
            name: name,
            description: description,
            render: render,
            beta: beta,
            image: image
        }
    }

    registerPlugins(descriptors) {
        descriptors.forEach(x => this.registerPlugin(x))
    }

    getTypes() {
        return this.types;
    }

    hasPlugin(type) {
        return this.getTypes()[type] !== undefined;
    }

    render(type, config, renderers) {
        if (this.types[type] === undefined) {
            throw new Error("Unknown plugin type "+ type)
        }

        return this.types[type].render(config, renderers)
    }
}

const pluginManager = new PluginManager();

export default function usePlugins() {
    return pluginManager
}
