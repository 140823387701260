import _ from "lodash";

export default class PluginConfig {
    data = {};

    constructor(lib, data) {
        this.lib = lib;
        this.setData(data);
    }

    isEnabled() {
        return Object.keys(this.getData().PublicConfig).length !== 0 || Object.keys(this.getData().PrivateConfig).length !== 0
    }

    getLib() {
        return this.lib;
    }

    setData(data) {
        const defaultData = {
            Type: "",
            PublicConfig: {},
            PrivateConfig: {}
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        this.data = newData;
    }

    getData() {
        return this.data;
    }

    async save() {
        return await this.lib.storePluginConfig(this);
    }

    async delete() {
        return await this.lib.deletePluginConfig(this);
    }
}