import Library from "./library";
import OrganisationUser from "./orguser";
import Trace from "./trace";
import BillingOverview from "./billingmonth";
import Team from "./team";

import PerspectiveIframeTab from "../plugins/PerspectiveIframeTab";
import OwnerIframeTab from "../plugins/OwnerIframeTab";

export default class Organisation {

    loader = undefined;
    org = {};
    libs = {};
    users = {};
    teams = {};
    traces = {};
    billing = {};
    user = undefined;
    isLoaded = false;

    constructor(user, loader, data) {
        this.user = user;
        this.loader = loader;
        this.data = data;
    }

    async ready() {
        const orgData = await this.loader.get("/orgs/"+this.getId() + "/full");
        this.data = orgData.data.org;

        this.libs = {};
        orgData.data.libs.forEach((lib) => {
            this.libs[lib.Name] = this.newLibrary(lib)
        });

        this.users = {};
        orgData.data.users.forEach((user) => {
            this.users[user.EMail] = this.newOrgUser(user)
        });

        this.teams = {};
        orgData.data.teams && orgData.data.teams.forEach((team) => {
            this.teams[team.Name] = this.newTeam(team)
        });

        this.traces = {};
        orgData.data.traces.forEach((trace) => {
            this.traces[trace.ID] = new Trace(this, this.loader, trace);
        });

        this.billing = {};
        Object.values(orgData.data.billing).forEach((billing) => {
            this.billing[billing.BillablePeriod] = new BillingOverview(this, billing);
        });

        try {
            const libsDetails = await this.user.doxyLoader.get("/v1/orgs/" + this.getId());
            libsDetails.data.libraries.forEach(lib => {
                if (this.libs[lib.id] !== undefined) {
                    this.libs[lib.id].overview = lib;
                }
            });
        } catch (e) {
            //whatever
        }

        this.isLoaded = true;
    }

    newLibrary(data) {
        return new Library(this.loader, this, data);
    }

    getAvailablePlugins() {
        //Beta plugins
        // plugins.registerPlugin(JiraConnect);
        // plugins.registerPlugin(AccelerateKPIs);

        return [
            PerspectiveIframeTab,
            OwnerIframeTab
        ]
    }

    newOrgUser(data) {
        return new OrganisationUser(this, data);
    }

    newTeam(data) {
        return new Team(this, data);
    }

    async saveLibrary(lib) {
        await this.loader.put(
            "/orgs/"+this.getId() + "/libs/" + lib.getId(),
            lib.data
        );

        return await this.ready();
    }

    async deleteLibrary(lib) {
        await this.loader.delete(
            "/orgs/"+this.getId() + "/libs/" + lib.getId()
        );

        return await this.ready();
    }

    async save(asNew) {
        return await this.user.saveOrg(this, asNew);
    }

    async delete() {
        return await this.user.deleteOrg(this);
    }

    getName() {
        return this.data.Name;
    }

    getSlug() {
        return this.getId();
    }

    getId() {
        return this.data.Slug;
    }

    isMarkedForDeletion() {
        return this.data.MarkedForDeletion
    }

    getLibs() {
        return this.libs;
    }

    getUsers() {
        return this.users;
    }

    getTeams() {
        return this.teams;
    }

    getTraces() {
        return this.traces;
    }

    getBillingOverview() {
        return this.billing;
    }

    async saveTeam(team) {
        await this.loader.put(
            "/orgs/"+this.getId() + "/teams/" + team.getId(),
            team.data
        );

        return await this.ready();
    }

    async deleteTeam(team) {
        await this.loader.delete(
            "/orgs/"+this.getId() + "/teams/" + team.getId(),
        );

        return await this.ready();
    }

    async saveUser(user) {
        await this.loader.put(
            "/orgs/"+this.getId() + "/users/" + user.getId(),
            user.data
        );

        return await this.ready();
    }

    async deleteUser(user) {
        await this.loader.delete(
            "/orgs/"+this.getId() + "/users/" + user.getId()
        );

        return await this.ready();
    }

    linkToDashboard() {
        return "/" + this.getId();
    }

    linkToSettings() {
        return "/" + this.getId() + "/settings";
    }

    linkToLibraries() {
        return "/" + this.getId() + "/libs";
    }

    linkToUsers() {
        return "/" + this.getId() + "/users";
    }

    linkToTeams() {
        return "/" + this.getId() + "/teams";
    }

    linkToTraces() {
        return "/" + this.getId() + "/traces";
    }

    linkToBilling() {
        return "/" + this.getId() + "/billing";
    }
}