import React from 'react';

import cx from "classnames";

import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import HeaderLogo from "./HeaderLogo";
import UserBox from "./UserBox";
import Menu from "./Menu";

function TopNavBar({org, orgs}) {
    return <ReactCSSTransitionGroup
        component="div"
        className={cx("app-header", "header-shadow")}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}>

        <HeaderLogo />

        <div className={cx(
            "app-header__content",
            {'header-mobile-open': false},
        )}>
            <div className="app-header-left">
                {/*<SearchBox/>*/}
                <Menu org={org} orgs={orgs}/>
            </div>
            <div className="app-header-right">
                {/*<HeaderDots/>*/}
                <UserBox/>
                {/*<HeaderRightDrawer/>*/}
            </div>
        </div>
    </ReactCSSTransitionGroup>;
}



export default TopNavBar;
