import React from 'react';
import PageTitle from "../Layout/PageTitle";
import {Link, Route, Switch} from "react-router-dom";
import {Button, Card, CardBody, Container} from "reactstrap";
import ReactTable from "react-table";
import NotFound from "../Layout/NotFound";
import Team from "./components/Team";

export default function Teams({org}) {
    const teams = Object.values(org.getTeams());

    return <>
        <Switch>
            <Route exact path={org.linkToTeams()} render={() => {
                return <>
                    <PageTitle
                        heading="Teams"
                        icon="pe-7s-car icon-gradient bg-mean-fruit"
                    >
                        {/*<Link to={org.linkToTeams() + "/+/import"} className={"mr-1"}><Button size="sm" color="secondary">Import team</Button></Link>*/}
                        <Link to={org.linkToTeams() + "/+"}><Button size="sm" color="info">Add team</Button></Link>
                    </PageTitle>
                    <Container fluid>
                        <Card className="mb-3">
                            <CardBody>
                                <ReactTable
                                    data={teams}
                                    filterable
                                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                                    columns={[
                                        {
                                            Header: "Teams",
                                            columns: [
                                                {
                                                    Header: "Team",
                                                    id: "team",
                                                    accessor: x => <>
                                                        <Link to={x.linkToEdit()}>{x.getId()}</Link>
                                                        {/*<p>{x.getData().Mission}</p>*/}
                                                    </>,
                                                    filterMethod: (filter, row) => {
                                                        return String(row[filter.id].props.children[0].props.children).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                                                    }
                                                },
                                                {
                                                    Header: "Lead",
                                                    id: "lead",
                                                    accessor: x => x.getData().Lead
                                                },
                                                {
                                                    Header: "Team members",
                                                    id: "members",
                                                    accessor: x => {
                                                        return x.getData().Members.join(", ")
                                                    }
                                                }
                                            ]
                                        },
                                    ]}
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </Container>
                </>
            }}/>

            <Route exact path={org.linkToTeams() + "/+"} render={() => {
                const team = org.newTeam({
                    Org: org.getId(),
                });
                return <>
                    <Team team={team} isNew={true} />
                </>
            }}/>

            <Route exact path={org.linkToTeams() + "/+/import"} render={() => {
                return <>
                    Not implemented yet
                </>
            }}/>

            <Route exact path={org.linkToTeams() + "/:team"} render={(route) => {
                const teamName = route.match.params.team;

                const team = teams.find(x => x.getId() === teamName);

                return <>
                    {
                        team &&
                        <Team team={team}/>
                    }
                    {
                        !team &&
                        <NotFound text={"Team not found"}/>
                    }
                </>
            }}/>
        </Switch>
    </>
}
