import Organisation from "./organisation";

export default class User {

    auth0User = undefined;
    loader = undefined;
    doxyLoader = undefined;
    orgs = {};
    isLoaded = false;

    constructor(loader, doxyLoader, auth0User) {
        this.loader = loader;
        this.doxyLoader = doxyLoader;
        this.auth0User = auth0User;
    }

    getId() {
        return this.auth0User["https://service360.io/user/email"];
    }

    async ready() {
        const data = await this.loader.get("/me");
        this.orgs = {};
        data.data.orgs.forEach((org) => {
            this.orgs[org.Slug] = this.newOrg(org)
        });

        this.adminOrgs = {};
        data.data.adminOrgs.forEach((org) => {
            this.adminOrgs[org.Slug] = this.newOrg(org)
        });

        this.permissions = data.data.permissions;
        this.isLoaded = true;
    }

    canCreateOrg() {
        return this.permissions["CanCreateOrganisation"] === true;
    }

    newOrg(data) {
        return new Organisation(this, this.loader, data);
    }

    async saveOrg(org, asNew) {
        if (asNew) {
            await this.loader.post(
                "/orgs/",
                org.data
            );
        } else {
            await this.loader.put(
                "/orgs/"+org.getId(),
                org.data
            );
        }
        return await this.ready();
    }

    async deleteOrg(org) {
        await this.loader.delete(
            "/orgs/"+org.getId()
        );

        return await this.ready();
    }

    getOrgs() {
        return this.orgs
    }

    getAdminOrgs() {
        return this.adminOrgs
    }
}