import _ from "lodash";

export default class BillingOverview {

    org = {};
    isLoaded = false;
    freeRepos = 5;
    pricePerRepo = 2;

    constructor(org, data) {
        this.org = org;
        this.setData(data);
    }

    setData(data) {
        const defaultData = {
            BillablePeriod: "",
            Repos: []
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        this.data = newData;
    }

    billableAmount() {
        return this.data.Repos.length <= this.freeRepos ? 0 : ((this.data.Repos.length - this.freeRepos) * this.pricePerRepo);
    }
}