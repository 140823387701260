import React from "react";
import Sidebar from "./components/Sidebar";

export default function AppMain({org, children, hideSideBar = false}) {
    return <div className="app-main min-vh-100">
        {
            !hideSideBar &&
            <Sidebar org={org}/>
        }
        <div className="app-main__outer">
            <div className="app-main__inner">
                {children}
            </div>
        </div>
    </div>;
}