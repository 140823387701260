import React, {useState} from 'react';
import PageTitle from "../Layout/PageTitle";
import {Alert, Button, Card, CardBody, Container} from "reactstrap";
import ReactTable from "react-table";

const freeRepos = 5;

export default function Billing({org}) {
    return <>
        <PageTitle
            heading="Billing"
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        />
        <Container fluid>
            <Alert color={"warning"}>
                <h4 className="alert-heading">No worries!</h4>
                Billing is shown for presentational purposes only. During Alpha phase you will not be billed.
            </Alert>
            <Card className="mb-3">
                <CardBody>
                    <ReactTable
                        data={
                            Object.values(org.getBillingOverview())
                                .sort((a, b) => a.data.BillablePeriod > b.data.BillablePeriod ? -1 : 1)
                        }
                        filterable
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                        columns={[
                            {
                                Header: "BillingOverview",
                                columns: [
                                    {
                                        Header: "Billable period",
                                        id: "BillablePeriod",
                                        accessor: x => x.data.BillablePeriod
                                    },
                                    {
                                        Header: "Billable repositories (" + freeRepos + " are free)",
                                        id: "reposCount",
                                        accessor: x => {
                                            return <RepoList repos={x.data.Repos.sort((a, b) => a < b ? -1 : 1)}/>
                                        }
                                    }
                                ]
                            },
                            {
                                Header: "Invoice",
                                columns: [
                                    {
                                        Header: "Amount to pay",
                                        id: "invoice",
                                        accessor: x => x.billableAmount() + " euro",
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={5}
                        className="-striped -highlight"
                    />
                </CardBody>
            </Card>
        </Container>
    </>;
}

function RepoList({repos}) {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => setExpanded(!expanded);

    if (repos.length < 7) {
        return repos.join(", ")
    }

    return <>
        <Button color={"link"} onClick={toggleExpanded}>
            {expanded && <i className="lnr-chevron-down"/>}
            {!expanded && <i className="lnr-chevron-right"/>}
        </Button>
        {
            !expanded &&
            <div>
                {repos.join(", ").substr(0, 200)}...
            </div>
        }
        {
            expanded && <>{repos.map(x => {
                return <>{x}<br/></>
            })}</>
        }
    </>
}
