import React, {useEffect, useState} from 'react';
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";
import SweetAlert from "sweetalert-react";

export default function Loaded(
    {
        item,
        message,
        children,
        loaderMethod = "ready",
        loaderCacheCheckField = "isLoaded",
        resetLoadedState = false,
        onSuccess = () => {}
    }
) {
    const [loaded, setLoaded] = useState(item[loaderCacheCheckField]);
    const [alertWasTriggered, setAlertWasTriggered] = useState(false);
    const [loadError, setLoadError] = useState(false);

    useEffect(() => {
        if (!loaded) {
            item[loaderMethod]()
                .then(() => {
                    if (onSuccess) {
                        onSuccess(item);
                    }
                })
                .catch((e) => {
                    setLoadError(e);
                    setAlertWasTriggered(true);
                })
                .finally(() => {
                    if (resetLoadedState) {
                        item[loaderCacheCheckField] = false;
                    }
                    setLoaded(true);
                });
        }
    }, [loaded, item]);

    if (!loaded) {
        return <BlockUi tag="div" blocking={true}
                        message={message}
                        loader={<Loader active type="ball-pulse"/>}>
            <div className="app-container app-theme-white"/>
        </BlockUi>;
    }

    const text = loadError ? loadError.message + "\n\n" + (loadError.response ? loadError.response.data : "") : "";

    return <>
        <SweetAlert
            title="Error"
            confirmButtonColor=""
            show={loadError !== false}
            text={text}
            confirmButtonText="Okay"
            onConfirm={() => {
                setLoadError(false);
            }}
            type="error"/>
        {!loadError && !alertWasTriggered && children}
    </>
}