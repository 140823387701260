import _ from "lodash";

export const GithubProfileType = "Github";

export default class OrganisationUser {

    org = {};
    isLoaded = false;

    constructor(org, data) {
        this.org = org;
        this.setData(data);
    }

    setData(data) {
        const defaultData = {
            Name: "",
            Info: "",
            Contacts: [],
            EMail: "",
            IsAdmin: false,
            Profiles: {}
        };
        let newData = {};
        _.merge(newData, defaultData, this.data, data);
        this.data = newData;
    }

    getId() {
        return this.data['EMail'];
    }

    getProfiles(type) {
        if (type) {
            if (!this.data['Profiles']) {
                return []
            }

            return this.data['Profiles'][type] || [];
        }

        return this.data['Profiles'] || {};
    }

    getName() {
        return this.data['Name'];
    }

    getInfo() {
        return this.data['Info'];
    }

    getContacts() {
        return this.data['Contacts'] || [];
    }

    isAdmin() {
        return !!this.data['IsAdmin'];
    }

    linkToEdit() {
        return "/" + this.org.getId() + "/users/" + this.getId();
    }

    save() {
        return this.org.saveUser(this);
    }

    delete() {
        return this.org.deleteUser(this);
    }
}