import React, {useEffect, useState} from 'react';
import ResizeDetector from 'react-resize-detector';

import {useAuth0} from "../../react-auth0-wrapper";
import SweetAlert from "sweetalert-react";
import axios from "axios";
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";
import AppContext from "./AppContext";
import {Route, Switch} from 'react-router-dom'
import TopNavBar from "../Layout/TopNavBar";
import AppMain from "../Layout/AppMain";
import NotFound from "../Layout/NotFound";
import {withTracker} from "../../hooks/withTracker";
import OrgSelector from "../OrgSelector";
import Organisation from "../Organisation";
import User from "../../domain/user";
import Loaded from "../Layout/Loaded";
import Libraries from "../Libraries";
import Library from "../Library";
import Users from "../Users";
import Traces from "../Traces";
import Trace from "../Traces/components/Trace";
import Billing from "../Billing";
import Dashboard from "../OrgDashboard";
import {useLoader} from "../../hooks/useLoader";
import usePlugins from "../../hooks/usePlugins";
import Teams from "../Teams";

import './App.css';

const MSG_LOGGING_IN = "Securely logging you in";
const MSG_LOADING_LIBRARY = "Loading your organisation";

function LoadingError({error}) {
    const {logout} = useAuth0();

    let title = "Error";
    let text = error.message;
    let alertType = "error";

    switch (error.response.status) {
        case 403:
            title = "We don't know you";
            alertType = "info";
            text = "Looks like you need to register (or request access) before you will be able to access Service360";
            break;
        case 404:
            title = "Organisation is not found";
            alertType = "error";
            text = "Oops! Looks like you have just created an organisation and your library is not yet ready. Wait a bit or check admin area for possible errors";
            break;
        default:
            break;
    }

    return <SweetAlert
        title={title}
        confirmButtonColor=""
        show={true}
        text={text}
        confirmButtonText="Logout"
        onConfirm={() => logout({
            returnTo: document.location.origin
        })}
        type={alertType}/>
}

function App({api, doxyURL, doxyApi, technoURL}) {
    const {loading, isAuthenticated, loginWithRedirect, getTokenSilently, user: auth0user} = useAuth0();
    const {setAdminLoader, setDoxyLoader} = useLoader();
    const [user, setUser] = useState();
    const [token, setToken] = useState();
    const [loadingError, setLoadingError] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Loading your Service360");
    const plugins = usePlugins();

    useEffect(() => {
        const loadUser = async () => {
            setLoadingMessage(MSG_LOGGING_IN);
            try {
                const token = await getTokenSilently();

                setLoadingMessage(MSG_LOADING_LIBRARY);

                const loader = axios.create({
                    baseURL: api,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                });

                const doxyLoader = axios.create({
                    baseURL: doxyApi,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                });

                const u = new User(loader, doxyLoader, auth0user);

                await u.ready();
                setAdminLoader(loader);
                setDoxyLoader(doxyLoader);
                setToken(token);
                setUser(u);
            } catch (e) {
                console.log(e);
                setLoadingError(e);
            }
        };

        if (!loading) {
            if (isAuthenticated) {
                loadUser();
            } else {
                loginWithRedirect();
            }
        }
    }, [loading, isAuthenticated]);

    window.u = user;

    return (<>
            {
                loadingError &&
                <LoadingError error={loadingError}/>
            }
            {
                !loadingError && (loading || !isAuthenticated || (isAuthenticated && !user)) &&
                <BlockUi tag="div" blocking={true}
                         keepInView={true}
                         message={loadingMessage}
                         loader={<Loader active type="ball-pulse"/>}
                >
                    <div className="app-container app-theme-white fixed-header"/>
                </BlockUi>
            }
            {
                !loadingError && !loading && isAuthenticated && user &&
                <ResizeDetector
                    handleWidth
                    render={({width}) => (
                        <AppContext.Provider value={{
                            user: user,
                            token: token,
                            technoURL: technoURL
                        }}>
                            <Loaded key={user.getId()} item={user} message={"Loading user data"}>
                                <Switch>
                                    <Route exact path="/reload" render={(route) => {
                                        route.history.goBack();

                                        return null
                                    }}/>

                                    <Route exact path="/" render={() => {
                                        return (
                                            <div className="app-container app-theme-white fixed-header">
                                                <TopNavBar orgs={user.getOrgs()}/>
                                                <AppMain>
                                                    <OrgSelector user={user} doxyURL={doxyURL}/>
                                                </AppMain>
                                            </div>
                                        );
                                    }}/>
                                    <Route exact path="/+" render={() => {
                                        const org = user.newOrg(
                                            {
                                                Name: "New organisation",
                                                AccountType: "trial"
                                            }
                                        );

                                        return (
                                            <div className="app-container app-theme-white fixed-header">
                                                <TopNavBar orgs={user.getOrgs()}/>
                                                <AppMain org={org} hideSideBar={true}>
                                                    <Organisation isNew={true} org={org}/>
                                                </AppMain>
                                            </div>
                                        );
                                    }}/>
                                    <Route path="/:orgSlug" render={(route) => {
                                        const orgSlug = route.match.params.orgSlug;
                                        const org = user.getOrgs()[orgSlug];

                                        if (org === undefined) {
                                            return <div className="app-container app-theme-white fixed-header">
                                                <TopNavBar orgs={user.getOrgs()}/>
                                                <AppMain>
                                                    <NotFound text={"Organisation is not found :("}/>
                                                </AppMain>
                                            </div>
                                        }

                                        document.title = "S360: " + org.getName();

                                        window.org = org;

                                        return <div className="app-container app-theme-white fixed-header">
                                            <TopNavBar orgs={user.getOrgs()}/>
                                            <Loaded key={org.getId()} item={org} message={"Loading organisation"}
                                                    onSuccess={(org) => {
                                                        plugins.deregisterAll();
                                                        plugins.registerPlugins(org.getAvailablePlugins());
                                                    }}
                                            >
                                                <AppMain org={org}>

                                                    <Switch>
                                                        <Route exact path="/:orgSlug" render={() => {
                                                            return <Dashboard org={org} doxyURL={doxyURL}/>
                                                        }}/>

                                                        <Route exact path="/:orgSlug/traces" render={() => {
                                                            return <Traces org={org}/>
                                                        }}/>

                                                        <Route path="/:orgSlug/traces/:traceId" render={(route) => {
                                                            const traceId = route.match.params.traceId;
                                                            const trace = org.getTraces()[traceId];

                                                            if (!trace) {
                                                                return <NotFound text={"Trace is not found :("}/>
                                                            }

                                                            return <Loaded item={trace} key={"trace" + trace.getId()}
                                                                           message={"Loading trace"}
                                                                           resetLoadedState={true}>
                                                                <Trace trace={trace} org={org}/>
                                                            </Loaded>
                                                        }}/>

                                                        <Route path="/:orgSlug/settings" render={() => {
                                                            return <Organisation org={org}/>
                                                        }}/>

                                                        <Route path="/:orgSlug/users" render={() => {
                                                            return <Users org={org}/>;
                                                        }}/>

                                                        <Route path="/:orgSlug/teams" render={() => {
                                                            return <Teams org={org}/>;
                                                        }}/>

                                                        <Route path="/:orgSlug/libs" render={() => {
                                                            return <>
                                                                <Switch>
                                                                    <Route exact path="/:orgSlug/libs" render={() => {
                                                                        return <Libraries org={org}/>
                                                                    }}/>;

                                                                    <Route exact path="/:orgSlug/libs/+"
                                                                           render={(route) => {
                                                                               const lib = org.newLibrary({
                                                                                   Name: "New Library"
                                                                               });

                                                                               return <Library lib={lib} isNew={true}
                                                                                               key={"+"}
                                                                                               technoURL={technoURL}
                                                                                               doxyURL={doxyURL}/>
                                                                           }}/>

                                                                    <Route path="/:orgSlug/libs/:libSlug"
                                                                           render={(route) => {
                                                                               const libSlug = route.match.params.libSlug;
                                                                               const lib = org.getLibs()[libSlug];

                                                                               if (lib === undefined) {
                                                                                   return <NotFound
                                                                                       text={"Library is not found :("}/>
                                                                               }



                                                                               return <Library lib={lib} key={libSlug}
                                                                                               technoURL={technoURL}
                                                                                               doxyURL={doxyURL}/>
                                                                           }}/>

                                                                    <Route component={NotFound}/>
                                                                </Switch>
                                                            </>;
                                                        }}/>

                                                        <Route path="/:orgSlug/billing" render={() => {
                                                            return <Billing org={org}/>
                                                        }}/>

                                                        <Route component={NotFound}/>
                                                    </Switch>

                                                </AppMain>
                                            </Loaded>
                                        </div>
                                    }}/>
                                    <Route component={NotFound}/>
                                </Switch>

                            </Loaded>
                        </AppContext.Provider>
                    )}
                />
            }
        </>
    )
}

export default withTracker(App);