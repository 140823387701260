import React, {Fragment} from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import cx from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import MetisMenu from "react-metismenu";
import {NavLink, useLocation} from "react-router-dom";
import usePlugins from "../../../../hooks/usePlugins";

export default function Sidebar({org}) {
    const location = useLocation();
    const plugins = usePlugins();

    let {
        backgroundColor,
        enableSidebarShadow,
    } = {};

    if (!org) {
        return null;
    }

    const libs = Object.values(org.getLibs()).map(lib => {
        const enabledPlugins = Object.keys(plugins.getTypes())
            .filter(type => lib.hasPluginConfig(type))
            .map(type => {
                return {
                    label: plugins.getTypes()[type].name,
                    to: lib.linkToPluginConfig(type)
                }
            });

        return {
            label: lib.getName(),
            icon: "pe-7s-plugin",
            to: lib.linkToOverview(),
            content: [
                {
                    label: "Sources",
                    to: lib.linkToSources()
                },
                {
                    label: "TechRadar",
                    to: lib.linkToTechRadarConfig()
                },
                ...enabledPlugins,
                {
                    label: "Plugins",
                    to: lib.linkToPlugins()
                }
            ]
        };
    });

    libs.unshift({
        label: "Add new library",
        icon: "pe-7s-plus",
        to: org.linkToLibraries() + "/+"
    });

    return <Fragment>
        <div className="sidebar-mobile-overlay"/>
        <ReactCSSTransitionGroup
            component="div"
            className={cx("app-sidebar", backgroundColor, {'sidebar-shadow': enableSidebarShadow})}
            transitionName="SidebarAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}>

            <PerfectScrollbar>
                <div className="app-sidebar__inner">

                    <h5 className="app-sidebar__heading">Libraries</h5>
                    <MetisMenu content={libs}
                               LinkComponent={RouterLink}
                               activeLinkTo={location.pathname}
                               className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
                    <h5 className="app-sidebar__heading">Traces</h5>
                    <MetisMenu
                        content={[
                            {
                                icon: 'pe-7s-display1',
                                label: 'Traces',
                                to: org.linkToTraces()
                            }
                        ]}
                        LinkComponent={RouterLink}
                        activeLinkTo={location.pathname}
                        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

                    <h5 className="app-sidebar__heading">Organisation setup</h5>
                    <MetisMenu content={[
                        {
                            icon: 'pe-7s-users',
                            label: 'Teams',
                            to: org.linkToTeams()
                        },
                        {
                            icon: 'pe-7s-add-user',
                            label: 'Users',
                            to: org.linkToUsers()
                        },
                        {
                            icon: 'pe-7s-wallet',
                            label: 'Billing',
                            to: org.linkToBilling()
                        },
                        {
                            icon: 'pe-7s-config',
                            label: 'Settings',
                            to: org.linkToSettings()
                        }
                    ]}
                               LinkComponent={RouterLink}
                               activeLinkTo={location.pathname}
                               className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

                </div>
            </PerfectScrollbar>
        </ReactCSSTransitionGroup>
    </Fragment>
}

function RouterLink({
                        className,
                        classNameActive,
                        classNameHasActiveChild,
                        active,
                        hasActiveChild,
                        to,
                        externalLink,
                        hasSubMenu,
                        activateMe,
                        toggleSubMenu,
                        children
                    }) {
    return (
        hasSubMenu || externalLink
            ? (
                <a
                    className={cx(
                        className,
                        hasActiveChild && classNameHasActiveChild
                    )}
                    target={externalLink ? '_blank' : undefined}
                    href={to}
                    onClick={toggleSubMenu}
                >
                    {children}
                </a>
            )
            : (
                <NavLink
                    className={cx(
                        className,
                        active && classNameActive
                    )}
                    exact
                    onClick={() => {activateMe(); return false}}
                    to={to}
                >
                    {children}
                </NavLink>
            )
    );
}
