import React, {useEffect, useState} from "react";
import SweetAlert from "sweetalert-react";
import BlockUi from "react-block-ui";
import {Button} from "reactstrap";

export default function ForceScrape({lib}) {
    const [traceID, setTraceID] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadError, setLoadError] = useState(false);

    useEffect(() => {
        const run = async () => {
            try {
                const tID = await lib.forceScrape();
                setTraceID(tID);
            } catch (e) {
                setLoadError(e);
            }
            setLoading(false);
        };

        if (loading && !traceID) {
            run();
        }
    }, [traceID, loading]);

    const forceScrape = () => {
        if (!traceID) {
            setLoading(true);
        }
    };

    if (lib.getSources().length === 0) {
        return null;
    }

    const title = traceID ? "Initiated: " + traceID : "Force scrape";

    const text = loadError ? loadError.message + "\n\n" + (loadError.response ? loadError.response.data : "") : "";

    return <>
        <SweetAlert
            title="Error"
            confirmButtonColor=""
            show={loadError !== false}
            text={text}
            confirmButtonText="Okay"
            onConfirm={() => {
                setLoadError(false);
            }}
            type="error"/>
        <BlockUi blocking={loading} className="d-inline-block">
            <Button size="sm" onClick={forceScrape} color={"warning"} disabled={traceID !== false}>{title}</Button>
        </BlockUi>
    </>
}
