import React from "react";

import {Link} from "react-router-dom";
import {Alert, Button, Card, CardBody, CardHeader, Container} from "reactstrap";
import LibraryOverview from "./LibraryOverview";
import ReactTable from "react-table";
import Ionicon from "react-ionicons";
import ForceScrape from "./ForceScrape";
import DeleteLibraryButton from "./DeleteLibraryButton";

export default function LibrarySources({lib, doxyURL}) {
    const sources = lib.getSources();

    return <Container fluid>
        <LibraryOverview library={lib} doxyURL={doxyURL}/>
        <Card className="mb-3">
            <CardHeader>
                <Link to={lib.linkToSources() + "/+"} className="ml-1"><Button size="sm"
                                                                               color="info">Add
                    source</Button></Link>

                <div className="btn-actions-pane-right">
                    <ForceScrape lib={lib}/>

                    <DeleteLibraryButton lib={lib} />
                </div>
            </CardHeader>
            <CardBody>
                {
                    sources.length === 0 &&
                    <Alert color="success">
                        <h4 className="alert-heading">Library is empty</h4>
                        There are no sources yet added to this library. <br/>
                        You can start <Link to={lib.linkToOverview() + "/+"}>adding
                        sources</Link>.
                    </Alert>
                }
                {
                    sources.length !== 0 &&
                    <ReactTable
                        data={sources}
                        filterable
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                        columns={[
                            {
                                Header: "Source",
                                columns: [
                                    {
                                        Header: "Name",
                                        id: "id",
                                        accessor: x => <Link
                                            to={x.linkToEdit()}>{x.getName()}</Link>,
                                    },
                                    {
                                        Header: "Type",
                                        id: "type",
                                        accessor: x => x.getSourceType(),
                                        width: 120
                                    },
                                    {
                                        Header: "Repo params",
                                        id: "repo",
                                        accessor: x => x.getGithubRepoParams().Owner + "/" + x.getGithubRepoParams().Repo,
                                    },
                                ]
                            },
                            {
                                Header: "Plugins",
                                columns: [
                                    {
                                        Header: "Passport",
                                        id: "passport",
                                        accessor: x =>
                                            <Ionicon fontSize="35px" color="#333"
                                                     icon={"ios-checkmark"}/>,
                                        width: 100
                                    },
                                    {
                                        Header: "Deps",
                                        id: "puml",
                                        accessor: x => x.getPlantUMLPlugin().Enabled ?
                                            <Ionicon fontSize="35px" color="#333"
                                                     icon={"ios-checkmark"}/> : "",
                                        width: 100
                                    },
                                    {
                                        Header: "API",
                                        id: "api",
                                        accessor: x => x.getAPIPlugin().Enabled ?
                                            <Ionicon fontSize="35px" color="#333"
                                                     icon={"ios-checkmark"}/> : "",
                                        width: 100
                                    },
                                    {
                                        Header: "Docs",
                                        id: "docs",
                                        accessor: x => x.getDocsPlugin().Enabled ?
                                            <Ionicon fontSize="35px" color="#333"
                                                     icon={"ios-checkmark"}/> : "",
                                        width: 100
                                    },
                                    {
                                        Header: "Releases",
                                        id: "releases",
                                        accessor: x => x.getReleasesPlugin().Enabled ?
                                            <Ionicon fontSize="35px" color="#333"
                                                     icon={"ios-checkmark"}/> : "",
                                        width: 100
                                    },
                                    {
                                        Header: "Contributors",
                                        id: "contributors",
                                        accessor: x => x.getContributorsPlugin().Enabled ?
                                            <Ionicon fontSize="35px" color="#333"
                                                     icon={"ios-checkmark"}/> : "",
                                        width: 110
                                    }
                                ]
                            },
                            {
                                Header: "Actions",
                                columns: [
                                    {
                                        id: "clone",
                                        width: 70,
                                        accessor: x => <Link to={x.linkToClone()}><Ionicon
                                            fontSize="25px" color="#333" icon={"md-copy"}
                                            title={"clone source"}/></Link>,
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />
                }

            </CardBody>
        </Card>
    </Container>
}