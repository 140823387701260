import {Card, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import {Link} from "react-router-dom";
import React from "react";

export default function LibraryOverview({library, doxyURL}) {
    const overview = library.getOverview();
    const lastTrace = library.getLastTrace();

    return <div className="card no-shadow bg-transparent no-border rm-borders mb-3">
        <Card>
            <Row className="no-gutters">
                {
                    overview && overview.servicesCount !== undefined &&
                    <Col md="12" lg="4">
                        <ListGroup flush>
                            <ListGroupWidgetItem name={"Services"} value={overview.servicesCount}/>
                            <ListGroupWidgetItem name={"Contributors"} value={overview.contributorsCount}/>
                        </ListGroup>
                    </Col>
                }
                <Col md="12" lg="4">
                    <ListGroup flush>
                        <ListGroupWidgetItem name={"Scrape hour"}
                                             subheading={"Full scrape is scheduled at this hour (UTC)"}
                                             value={library.getScrapeHour()}/>
                        {
                            lastTrace &&
                            <ListGroupWidgetItem name={"Last trace"}
                                                 subheading={<Link
                                                     to={lastTrace.linkToOverview()}>{lastTrace.getCreatedAt().toISOString().slice(0, 19).replace("T", " ")}</Link>}
                                // value={"Here will completion percentage"}
                            />
                        }
                        {
                            lastTrace === undefined &&
                            <ListGroupWidgetItem value={<span>&nbsp;</span>}/>
                        }
                    </ListGroup>
                </Col>
                <Col md="12" lg="4">
                    {overview && overview.servicesCount !== undefined &&
                    <ListGroup flush>
                        <ListGroupWidgetItem name={"Service360 view"}
                                             subheading={"Go to client view"}
                                             value={<a href={doxyURL + library.org.getId() + "/" + library.getId()}><i
                                                 className="lnr-arrow-right"> </i></a>}/>
                        {overview && overview.updatedAt !== undefined &&
                        <ListGroupWidgetItem name={"Last updated"}
                                             subheading={overview.updatedAt.slice(0, 19).replace("T", " ")}/>
                        }
                    </ListGroup>
                    }
                </Col>
            </Row>
        </Card>
    </div>;
}

export function ListGroupWidgetItem({name, subheading, value, children, noSubHeading = false, widgetClass = "widget-numbers"}) {
    return <ListGroupItem className="bg-transparent">
        <div className="widget-content p-0">
            <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                        <div className="widget-heading">
                            {name !== undefined && name}
                            {name === undefined && <span>&nbsp;</span>}
                        </div>
                        {
                            !noSubHeading &&
                            <div className="widget-subheading">
                                {subheading !== undefined && subheading}
                                {subheading === undefined && <span>&nbsp;</span>}
                            </div>
                        }
                    </div>
                    {
                        value !== undefined &&
                        <div className="widget-content-right">
                            <div className={widgetClass}>
                                {value}
                            </div>
                        </div>
                    }
                    {
                        children
                    }
                </div>
            </div>
        </div>
    </ListGroupItem>
}