import React from 'react';
import {NavLink as NavLinkRR, withRouter} from 'react-router-dom';

import {Nav, NavItem, NavLink} from 'reactstrap';

import Ionicon from 'react-ionicons'

function Menu({orgs}) {
    if (!orgs) {
        return null;
    }

    return (
        <Nav className="header-megamenu">
            {
                Object.values(orgs).map((org, i) => {
                    return <NavItem>
                        <NavLink tag={NavLinkRR} to={org.linkToDashboard()} activeClassName="active">
                            <Ionicon icon={"ios-list-box-outline"}/>
                            {org.getName()}
                        </NavLink>
                    </NavItem>
                })
            }

            {/*<NavItem>*/}
            {/*    <NavLink tag={NavLinkRR} to={org.linkToTraces()} className={"nav-link"}>*/}
            {/*        <Ionicon icon={"ios-pulse-outline"} />*/}
            {/*        Traces*/}
            {/*    </NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink tag={NavLinkRR} to={org.linkToUsers()} className={"nav-link"}>*/}
            {/*        <Ionicon icon={"ios-person-outline"} />*/}
            {/*        Users*/}
            {/*    </NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink tag={NavLinkRR} to={org.linkToBilling()} className={"nav-link"}>*/}
            {/*        <Ionicon icon={"ios-card-outline"} />*/}
            {/*        Billing*/}
            {/*    </NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink tag={NavLinkRR} to={org.linkToSettings()} className={"nav-link"}>*/}
            {/*        <Ionicon icon={"ios-settings-outline"} />*/}
            {/*        Settings*/}
            {/*    </NavLink>*/}
            {/*</NavItem>*/}

        </Nav>
    );
}

export default withRouter(Menu);
