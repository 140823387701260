import React, {Fragment, useContext} from "react";
import {Button} from "reactstrap";
import {Auth0Context} from "../../../react-auth0-wrapper";

export default function UserBox() {
    const { isAuthenticated, logout, user } = useContext(Auth0Context);

    if (!isAuthenticated) {
        return null;
    }

    return <Fragment>
        <div className="header-btn-lg pr-0">
            <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                        <img width={42} className="rounded-circle" src={user.picture} alt=""/>
                    </div>
                    <div className="widget-content-left  ml-3 header-user-info">
                        <div className="widget-heading">
                            {user.nickname}
                        </div>
                        <div className="widget-subheading">
                            {user.name}
                        </div>
                    </div>

                    <div className="widget-content-right header-user-info ml-3">
                        <Button className="btn-shadow p-1" size="sm" onClick={logout} color="secondary">
                            Logout
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}