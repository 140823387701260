import React, {useState} from 'react';
import usePlugins from "../../../hooks/usePlugins";
import {Redirect} from "react-router-dom";
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";
import SweetAlert from "sweetalert-react";
import {Badge, Button, Card, CardFooter, CardHeader, Container, CardBody} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";
import {IconTooltip, Tooltip} from "../../Layout/Tooltip";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

export default function Plugin({library, pluginType}) {
    const plugins = usePlugins();

    const renderers = {
        BasicFrame: BasicFrame
    }

    return plugins.render(pluginType, library.getPluginConfig(pluginType), renderers)
}

function BasicFrame({
                        pluginDescriptor, persist = () => {
    }, redirectAfterSaveTo, wasModified, onSuccess = () => {
    }, children
                    }) {
    const [persistenceResult, setPersistenceResult] = useState({
        isLoading: false,
        redirectAfterSave: false,
        error: false
    });

    const {isLoading, redirectAfterSave, error} = persistenceResult;

    const handleSubmit = async () => {
        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        try {
            await persist();

            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                redirectAfterSave: true
            });
            if (onSuccess) {
                onSuccess()
            }
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };

    if (redirectAfterSaveTo && redirectAfterSave) {
        return <Redirect to={redirectAfterSaveTo}/>
    }

    let errorMsg = error ? error.message + "\n\n" + (error.response ? error.response.data : "") : "";

    return <>
        <BlockUi tag="div" blocking={isLoading}
                 message={"Saving"}
                 loader={<Loader active type="ball-pulse"/>}>
            <SweetAlert
                title="Error"
                confirmButtonColor=""
                show={error !== false}
                text={errorMsg}
                confirmButtonText="Okay"
                onConfirm={() => {
                    setPersistenceResult({
                        ...persistenceResult,
                        error: false
                    });
                }}
                type="error"/>
            <Container fluid>
                <AvForm onValidSubmit={handleSubmit}>
                    <Card className="mb-3">
                        <CardHeader>{pluginDescriptor.name} <Tooltip className={"ml-1"}
                                                                     tip={pluginDescriptor.beta}><Badge
                            color={"warning"}>beta</Badge></Tooltip>
                            {/*<div className="btn-actions-pane-right actions-icon-btn">*/}
                            {/*    <Button color="primary" className="mt-1" type="submit">*/}
                            {/*        Save*/}
                            {/*        {*/}
                            {/*            !persistenceResult.wasSavedAfterModification &&*/}
                            {/*            <IconTooltip className={"ml-1"} icon={faExclamationTriangle}*/}
                            {/*                         tip={"Config was modified and not saved"}/>*/}
                            {/*        }*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </CardHeader>
                        {
                            pluginDescriptor.description &&
                            <CardBody>
                                {pluginDescriptor.description}
                            </CardBody>
                        }
                    </Card>
                    {children}
                    <Card className={"mt-3"}>
                        <CardFooter>
                            <Button color="primary" className="mt-1" type="submit" disabled={wasModified !== undefined && !wasModified}>
                                Save
                                {
                                    wasModified !== undefined && wasModified &&
                                    <IconTooltip className={"ml-1"} icon={faExclamationTriangle}
                                                 tip={"Config was modified and not saved"}/>
                                }
                            </Button>
                        </CardFooter>
                    </Card>
                </AvForm>
            </Container>
        </BlockUi>
    </>
}